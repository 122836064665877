.Dialog {
  width: 500px;
  border-radius: 2px;
  box-shadow: var(--box-shadow-2);
}

.dialogContent {
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  margin-bottom: 16px;
}

.buttonWrapper {
  display: flex;
  justify-content: space-evenly;
}

.buttons {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.buttons {
  width: 200px;
  border-color: transparent;
  background-color: #ff0000;
  color: white;
  font-size: 14px;
  font-family: Arial;
}

.buttons:hover {
  background-color: #cf0707 !important;
  color: white !important;
  font-size: 14px;
  font-family: Arial;
}
