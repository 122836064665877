.report-style-class {
  height: 100vh;
  padding: 0px;
  margin: 0 auto;
  width: 100%;
  background-color: #e9e9e9;
}

body {
  font-family: 'Open Sans';
  margin: 0;
}

.header {
  background: #3476ae 0 0 no-repeat padding-box;
  border: 1px solid #707070;
  height: 55px;
  left: 0;
  top: 0;
}

.displayMessage {
  color: #000000;
  font: normal 22px/27px Open Sans;
  letter-spacing: 0;
  margin-top: 1%;
  opacity: 1;
  text-align: center;
}

.hr {
  border: 1px solid #e0e0e0;
  opacity: 1;
}

.controls {
  margin-top: 1%;
  text-align: center;
}

.footer {
  background: #eef3f8 0 0 no-repeat padding-box;
  bottom: 0;
  height: 39px;
  opacity: 1;
  position: absolute;
  width: 100%;
}

.footer-text {
  font: Regular 16px/21px Segoe UI;
  height: 21px;
  letter-spacing: 0;
  margin-top: 9px;
  opacity: 1;
  position: relative;
  text-align: center;
  width: 100%;
}

.footer-text > a {
  color: #278ce2;
  font: Regular 16px/21px Segoe UI;
  letter-spacing: 0;
  text-decoration: underline;
}

.title {
  color: #ffffff;
  font: Bold 22px/27px Segoe UI;
  letter-spacing: 0;
  margin: 13px;
  margin-left: 36px;
  opacity: 1;
  text-align: left;
}

button {
  background: #337ab7;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  font-size: medium;
  height: 35px;
  margin-right: 15px;
  width: 150px;
}

button:onfocus {
  outline: none;
}

iframe {
  border: none;
}

.loadingMsg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 22px;
}

@import url('https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css');
.run-btn {
  background-color: #eb4734;
  border-color: #eb4734;
  width: 70px;
  height: 35px;
  color: white;
}
.run-btn:hover {
  background-color: #dd3c30;
  border-color: #dd3c30;
  color: white;
}

.login-btn {
  width: 115px;
  height: 25px;
  background: #4e9caf;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  line-height: 25px;
  cursor: pointer;
}

/* Sign in */

body {
  color: #797979;
  background: #fff;
  font: 18px/1.6667 'Open Sans', 'Arial', 'Helvetica Neue', 'Helvetica',
    sans-serif;
  min-width: 320px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.masthead {
  height: 590.61px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.navbar.nav-bg-colour {
  background-color: #1d1d1d !important;
}

.navbar.nav-bg-colour .nav-link {
  line-height: 40px;
  height: 40px;
  padding: 0 16px 0 16px;
  width: auto;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  text-align: left;
  text-decoration: none;
  border: 0;
  border-radius: 0;
  font-family: inherit;
  font-size: 18px;
  font-weight: 700;
  outline: none;
}
.btn-try-elevate:hover {
  color: #ffffff;
}
.signIn-btn:hover {
  color: #ff2918;
}
.btn-try-elevate {
  width: 190px;
  height: 40px;
  border-radius: 0px;
  background: #ee4036;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin-right: 47px;
}

.signIn-btn {
  height: 40px;
  width: 190px;
  border-radius: 0px;
  background: #ffffff;
  border: 1px solid #ee4036;
  box-sizing: border-box;
  border-radius: 0%;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  color: #ee4036;
}

.content-bg {
  background-color: #1d1d1d;
}

.hover {
  cursor: pointer;
}
