.password-link {
  color: #eb4734;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.password-link:hover {
  color: #eb4734;
}

.width-primary-interest {
  width: 70%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.primary-interest {
  outline: none;
  min-width: 50%;
  background-color: white;
  opacity: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  max-width: 80%;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 10px;
}

@media only screen and (min-width: 350px) and (max-width: 767px) {
  .width-primary-interest {
    width: 100% !important;
  }
  .primary-interest {
    min-width: 100%;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .width-primary-interest {
    width: 95% !important;
  }
}
@media only screen and (min-device-width: 1025px) and (max-device-width: 1260px) {
  .width-primary-interest {
    width: 80% !important;
  }
}
@media screen and (max-width: 992px) {
  .invite-team-row {
    gap: 1px !important;
    flex-wrap: wrap !important;
  }
}
