.message {
  box-shadow: var(--box-shadow-1);
  position: fixed;
  color: #fff;
  background-color: green;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  padding: 4px;
  /* top: 0px; */
  padding: 10px 15px 10px 15px;
  right: 40%;
  width: auto;
  z-index: 9001 !important;
  /* border-radius: 4px; */
  text-align: center;
}

.error {
  background-color: #eb4734;
}
