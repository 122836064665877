.cloudDesc p {
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 18px;
  line-height: 25px;
  color: #424242;
}
.cloudDesc div {
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 18px;
  line-height: 25px;
  color: #424242;
}

.heading {
  font-weight: 700;
}

.mainContainer {
  padding-top: 14px;
  padding-bottom: 15px;
}

.detailmodel {
  color: #eb4734;
  cursor: pointer;
  font-weight: bolder;
}
.list-row {
  display: inline;
}
