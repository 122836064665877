.logo {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  margin-top: -6px;
  margin-bottom: -6px;
  margin-right: 50px;
}
.logo img {  
  width: 195px;
}
/* .logo img {
  height: 100px;
} */
