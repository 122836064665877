.entiName {
  width: 30%;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
}
.entiDesc {
  width: 70%;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
}

.DataValue {
  display: flex;
  padding: 19px;
  border-bottom: 1px solid #d9d9d9;
  width: 90%;
  justify-content: center;
  gap: 26px;
}

.DataValue:last-child {
  border-bottom: none;
}

.closeimg {
  float: right;
  margin-top: -2%;
  margin-right: -2%;
  cursor: pointer;
}

.modalHeading {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #424242;
}

@media screen and (min-width: 320px) and (max-width: 1220px) {
  .DataValue {
    flex-direction: column;
    gap: 10px;
  }
  .entiDesc,
  .entiName {
    width: 100%;
  }
  .closeimg {
    margin-top: -4%;
    margin-right: -4%;
  }
}
@media screen and (min-width: 2250px) and (max-width: 3900px) {
  .closeimg {
    padding: 18px 21px;
  }
}
