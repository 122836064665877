.menu-active {
  text-decoration: none !important;
  border-bottom: 3px solid white !important;
  width: fit-content !important;
  font-weight: 700 !important;
  /* padding: 0px !important; */
  padding-bottom: 7px !important;
  height: 40px !important;
  margin-left: 15px;
}

.sub-menu-active {
  text-decoration: none !important;
  font-weight: 700 !important;
  padding: 0px !important;
  margin-left: 15px;
}
.menu {
  font: 14px 'Open Sans';
  font-weight: 500;
  width: fit-content;
  height: 40px !important;
  padding-bottom: 8px !important;
  margin-left: 15px;
  color: #ffffff !important;
}

.img-fluid {
  width: 195px !important;
}

/* Welcome Dialog  */
.welcome-title-popup {
  padding: 20px 20px;
}

.welcome-title-popup ul {
  margin: 0px;
  padding: 0px;
}

.welcome-title-popup ul li {
  list-style: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  padding: 15px 0px;
  color: #333333;
  border-bottom: 1px solid #c4c4c4;
}

.welcome-title {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  color: #333333;
  text-decoration: none !important;
  /* border-bottom: 4px solid #ee4036 !important; */
}

.welcome-typo {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.51px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
  padding: 15px 0px;
}
.welcome-title-popup a {
  color: #333333;
}
.welcome-title-popup a:hover {
  outline: none;
  text-decoration: none;
  color: #333333;
}
.welcome-title-popup a:visited {
  outline: none;
  text-decoration: none;
  border: none;
}

.welcome-progress {
  height: 9px;
  background-color: #eb4734 !important;
}
.progress-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */
  text-align: center;
  color: #000000;
  margin-bottom: 5px;
}
.h-20 {
  height: 20px;
}
video {
  /* override other styles to make responsive */
  width: 100% !important;
  height: auto !important;
  /* object-fit: cover; */
}
.account-ready {
  text-align: right;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #424242;
  padding-top: 5px;
}
.take-me-button {
  width: auto !important;
  height: 40px !important;
  background-color: #ee4036;
  border-radius: 0% !important;
  padding: 0px 20px 0px 20px !important;
  text-align: left;
}

/* Direct integration CSS */

.direct-heading {
  font-size: 24px;
  height: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  text-align: left;
  border-bottom: 1px none #f8f6f6;
  position: relative;
  z-index: 1;
  color: black;
}
.direct-heading :before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 50%;
  border-bottom: 1px solid #c4c4c4;
}

.table-border {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  margin-top: 2%;
  margin-bottom: 2%;
  padding: 30px;
}

.table-ui td:not(:first-child) {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  color: #424242;
}

.table-ui tbody > tr:last-child > td {
  border-bottom: 0 !important;
}

.table-header th {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 18px;
  line-height: 25px;
  align-items: center;
  color: #000000;
}
.border-top-none {
  border-top: none !important;
  border-bottom: 1px solid #c4c4c4 !important;
}
.border-bottom-none {
  border-bottom: none !important;
}
.table tbody tr td {
  border-bottom: 1px solid #c4c4c4 !important;
}

.loading-div {
  top: 50%;
  left: 45%;
  position: fixed;
  z-index: 10000;
}

/* Editable Table CSS  */
.editable-table tbody tr td:nth-child(1) {
  width: 40%;
}
.editable-table tbody tr td:nth-child(2) {
  width: 30%;
}
.editable-table tbody tr td:nth-child(3) {
  width: 30%;
}
table tr td a:hover {
  border: none;
  outline: none;
}
.edit-cell {
  padding: 0px;
}
.edit-cell input {
  border: none;
  padding: 4px 8px;
  width: 100%;
  outline: 1px solid #40a9ff;
  margin-bottom: 5px;
  border-radius: 3px;
}

.edit-cell input:focus {
  outline: 1px solid #40a9ff;
}

.edit-cell div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.edit-cell {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.edit-button {
  width: 30px;
  height: 30px;
  padding: 0px;
  color: #222;
  margin: 0px;
  margin-left: 5px;
  border-radius: 4px;
  background: #f5f6f8;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}
.edit-img {
  height: 15px;
  width: 30px;
}

.edit-cell div button:hover {
  background: #eaeaeb;
}

.edit-cell div button:focus {
  outline: none;
}

.edit-cell div button img {
  width: 10px;
}

.hover {
  cursor: pointer;
}
.error-msg {
  font-size: 12px;
  color: red;
}
button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
  color: #ee4036;
}
/* End of Direct integration CSS */

/* Connectiom message */
.msg-anchor {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  color: #4578fb;
  margin-left: 40px;
}

.list li {
  float: left;
  list-style-position: inside;
  list-style-type: disc;
  margin-right: 1em;
  font-weight: bold;
}

.list {
  padding-left: 1em;
}

.list:after {
  /* Clearfix hack */
  content: '';
  display: block;
  clear: both;
}

/* unvisited link */
a:link {
  text-decoration: none !important;
}

/* visited link */
a:visited {
  text-decoration: none !important;
}

a:active,
a:hover,
a:focus {
  text-decoration: none !important;
  outline: none !important;
}

.limited-first-line {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  color: #424242;
}
.limited-second-line {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #424242;
  margin-top: 20px;
}

.expanded-first-line {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  color: #424242;
}
/* End of Connectiom message */

.mt-40 {
  margin-top: 40px;
}

/* Home page style */

.welcome-msg {
  display: flex;
  justify-content: space-between;
  align-items: top;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  color: #424242;
  -webkit-text-stroke-width: thin;
}
.card-style {
  background: #ffffff;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  cursor: pointer;
  min-height: 400px;
  max-height: 400px;
  overflow-y: auto;
  /* width: 300px; */
  padding: 20px;
  padding-bottom: 5px !important;
  /* max-width: 345px !important; */
}

.card-style:hover .card-title {
  color: #bf1625 !important;
}
.card-style:hover {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
}
.card-title {
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 20px !important;
  line-height: 28px !important;
  color: #424242 !important;
  letter-spacing: 1px;
  -webkit-text-stroke-width: thin;
}

.card-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 23px !important;
  color: #424242 !important;
  /* text-align: justify; */
}

.card-image {
  display: flex;
  margin: auto;
  margin-top: 20px;
  height: 100px;
  width: 155px;
}

.row-heading {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #424242;
}

.grayscale {
  /* filter: grayscale(100%) !important; */
}
.disable-img {
  filter: grayscale(100%) !important;
}

.grayscale:hover {
  cursor: default;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
}

.coming-soon {
  color: #bf1625;
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 24px !important;
  line-height: 28px !important;
  /* margin-bottom: 5px; */
}

.coming-soon-bottom {
  color: #bf1625;
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 24px !important;
  line-height: 28px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
}

.info-msg {
  font-weight: bold !important;
  font-size: 15.5px !important;
  color: #bf1625 !important;
  /* text-align: center !important; */
  margin-top: 130px !important;
  line-height: 20px;
  padding-top: 70px !important;
  margin-left: 20px !important;
}
.uvs {
  position: relative !important;
}

.card-img-bottom {
  width: 155px !important;
}
.card-img-overlay {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1000;
}
.uvs:hover .card-img-overlay {
  opacity: 0.8;
}
.card-uvs {
  position: absolute;
  top: 0%;
  left: 20%;
  -webkit-transform: translate(-10%, -10%);
  -ms-transform: translate(-10%, -10%);
  transform: translate(-10%, -10%);
}

.uvs:hover .grayscale-hover {
  filter: grayscale(90%) !important;
}
.uvs:hover .grayscale-hover .card-text {
  visibility: hidden;
}

/* End of Home page */

/* Start Main Menu */

.navbar-toggler:focus {
  outline: 0px auto !important;
}

@media only screen and (max-width: 480px) {
  .img-fluid {
    width: 165px !important;
  }

  .navbar-brand div {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .bg-md-white a button {
    color: #000000 !important;
  }
  .navbar-dark .navbar-toggler {
    text-align: right;
    float: right;
  }
  .bg-sm-white {
    background-color: white;
  }
  .text-sm-dark {
    color: #000000;
  }

  .menu-active {
    text-decoration: none !important;
    border-bottom: 3px solid rgb(0, 0, 0) !important;
    width: fit-content !important;
    font-weight: 700 !important;
    /* padding: 0px !important; */
    padding-bottom: 7px !important;
    height: 40px !important;
    margin-left: 15px;
    background-color: white !important;
    color: black !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
  }
  .navbar {
    display: block !important;
  }
  .navbar-dark .navbar-toggler {
    position: absolute;
    right: 12px !important;
  }
  .userprofile-icon {
    position: absolute;
    right: 44px !important;
    top: 13px;
  }
  a button:hover {
    border: 2px solid white !important;
    color: #1d1d1d !important;
  }
}
@media (min-width: 481px) and (max-width: 990px) {
  .navbar-dark .navbar-toggler {
    text-align: right;
    float: right;
  }

  .bg-md-white {
    background-color: white;
  }
  .tab-nav,
  .tab-menu {
    width: 100% !important;
    margin: 0 !important;
  }
  .bg-md-white a button {
    color: #000000 !important;
  }
  .menu-active {
    text-decoration: none !important;
    border-bottom: 3px solid rgb(0, 0, 0) !important;
    width: fit-content !important;
    font-weight: 700 !important;
    /* padding: 0px !important; */
    padding-bottom: 7px !important;
    height: 40px !important;
    margin-left: 15px;
    background-color: white !important;
    color: black !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
  }
  img.hover.userprofile-icon {
    position: absolute;
    right: 44px;
    top: 13px;
  }
  a button:hover {
    border: 2px solid white !important;
    color: #1d1d1d !important;
  }
}
.mdi-icon {
  margin-bottom: 0px !important;
}
@supports (-moz-appearance: none) {
  .mdi-icon {
    margin-bottom: 0px !important;
  }
  .singleSelect .searchBox {
    pointer-events: none;
  }
}
@media (min-width: 992px) {
  .navbar-brand div {
    margin-right: 0px !important;
  }
  .userprofile-icon {
    position: absolute;
    right: 0px !important;
    top: 13px;
  }
}
.navbar-dark .navbar-toggler {
  position: absolute;
  right: 15px;
}
/* #responsive-navbar-nav .navbar-nav a div > button:hover,
#responsive-navbar-nav .navbar-nav a div > button:focus,
#responsive-navbar-nav .navbar-nav a > button:hover,
#responsive-navbar-nav .navbar-nav a > button:focus {
  border: 0px solid #1b1b1b !important;
  transition: ease 0s !important;
} */

.userprofile-icon {
  position: absolute;
  right: 30px;
  top: 13px;
}
.userprofile-icon button:hover {
  border: none;
}
.userprofile-icon button {
  width: 40px;
}

@media (min-width: 320px) and (max-width: 359px) {
  .userprofile-icon {
    top: 6px;
  }
  .card-style {
    max-width: 270px !important;
    min-width: 270px !important;
  }
  .col {
    flex-grow: 0 !important;
  }
  .welcome-msg {
    font-size: 15px;
  }
  .row-heading {
    font-size: 20px;
  }
  .card-title {
    font-size: 15px !important;
  }
  .card-text {
    font-size: 14px !important;
  }
}
@media (min-width: 360px) and (max-width: 640px) {
  .card-style {
    max-width: 320px !important;
    min-width: 320px !important;
  }
  .col {
    flex-grow: 0 !important;
  }
  .welcome-msg {
    font-size: 18px;
  }
  .row-heading {
    font-size: 22px;
  }
  .card-title {
    font-size: 18px !important;
  }
  .card-text {
    font-size: 15px !important;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .card-style {
    max-width: 315px !important;
    min-width: 315px !important;
  }
  .col {
    flex-grow: 0 !important;
  }
  .welcome-msg {
    font-size: 25px;
  }
  .row-heading {
    font-size: 25px;
  }
  .card-title {
    font-size: 20px !important;
  }
  .card-text {
    font-size: 15px !important;
  }
}
@media (min-width: 1008px) and (max-width: 1365px) {
  .card-style {
    max-width: 255px !important;
    min-width: 255px !important;
  }
  .col {
    flex-grow: 0 !important;
  }
}

@media (min-width: 1366px) and (max-width: 2559px) {
  .card-style {
    max-width: 300px !important;
    min-width: 270px !important;
  }
  .col {
    flex-grow: 0 !important;
  }
}
@media (min-width: 2560px) and (max-width: 3840px) {
  .card-style {
    max-width: 430px !important;
    min-width: 430px !important;
  }
  .col {
    flex-grow: 0 !important;
  }
  .welcome-msg {
    font-size: 45px;
  }
}

/* API Style */

.copy-btn {
  background-color: #eb4734;
  border-color: #eb4734;
  width: 100px;
  /* height: 35px; */
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  border-radius: 0 !important;
  color: #ffffff;
  margin-left: 10px;
}
.copy-btn:hover {
  background-color: #dd3c30;
  border-color: #dd3c30;
  color: white;
}
.copy-btn:focus {
  outline: none;
}
.copy-msg {
  display: inline-block;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  padding: 10px;
  box-sizing: border-box;
  max-width: 350px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  position: absolute;
  z-index: 100px;
}

.close-btn {
  float: right;
  padding-left: 10px;
  /* padding: 2px 5px; */
}

.copy-tick {
  background-color: green;
  color: white;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
  margin-right: 5px;
}
.link-tick {
  background-color: #ffffff;
  color: #eb4734;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
}

.invite-team {
  border-top: 1px solid black;
}

.form-control {
  width: max-content;
  height: max-content;
}

/* Start Solution pack */

.fav {
  height: 20px;
  width: 20px !important;
  margin-left: 90%;
}

.card-image-solution {
  display: flex;
  margin: auto;
  height: 105px;
  width: 155px;
}
/* //Footer Style */
.main-footer {
  height: 225px;
  font-family: 'Open Sans';
  color: #ffffff;
}
.footer-left-content {
  padding: 20px;
  margin-left: 10%;
}

.footer-learn-more {
  width: 315px;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  align-items: center;
  margin-top: 20px;
}

.footer-copy {
  float: right;
  padding: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  align-items: center;
  text-align: right;
  margin-right: 50px;
}

.no-anchor {
  color: white !important;
}
.no-anchor:link {
  color: white !important;
}

.no-anchor:hover {
  color: white !important;
}

.no-anchor:active {
  color: white !important;
}
.download {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  color: #ff2e2e;
  font-weight: bolder;
  padding-left: 0.5rem;
}
.card-download {
  font-size: 0.7rem !important;
  margin-bottom: 13px;
}

.download-link {
  display: flex;
  flex-direction: row;
  margin-left: 15px;
}

@media screen and (max-width: 1890px) and (min-width: 768px) {
  .card-control {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 4 !important;
    -webkit-box-orient: vertical !important;
  }
}
@media screen and (max-width: 768px) {
  .card-control {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 4 !important;
    -webkit-box-orient: vertical !important;
  }
}

@media only screen and (min-width: 1850px) {
  .card-control {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 4 !important;
    -webkit-box-orient: vertical !important;
  }
}

@media screen and (max-width: 1920px) and (min-width: 768px) {
  .card-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }
}
@media screen and (max-width: 768px) {
  .card-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}

@media only screen and (min-width: 1850px) {
  .card-text {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

/* //Coverage reports */
.coverage-reports-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #424242;
}
.coverage-reports-sub-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}
.div-bottom-border {
  border-bottom: 1px solid rgb(51, 49, 49);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  color: #424242;
}
.download-icon {
  float: right;
  cursor: pointer;
}
.arrow-icon {
  float: right;
  cursor: pointer;
  margin-top: 10px;
}
.coverage-reports-section-header {
  margin-bottom: 10;
  background-color: rgba(244, 244, 244, 1) !important;
  border-bottom: 1.5px solid black !important;
  color: black;
  padding: 0px !important;
  padding-bottom: 10px !important;
  margin-bottom: 20px !important;
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 29px;
  color: #424242;
}

/* Solution Detail */
.detail-bg {
  display: inline-block;
  width: 100%;
  background: #ffffff;
  margin-top: -19px;
}
.detail-img {
  /* margin-bottom: -125px; */
  width: 0%;
}
.detail-img,
.detail-heading,
.solution-detail {
  display: inline;
  /* margin: auto; */
}
.detail-img img {
  width: 74.5px;
  margin-bottom: 12px;
}
.detail-grid {
  padding-left: 100px;
}
.detail-container {
  display: flex;
  margin-top: 20px;
}
.grid ul {
  line-height: 24px;
  word-spacing: 1px;
  column-count: 2;
  list-style-position: outside;
}
.solution-detail {
  color: #eb4734;
  height: 39px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  /* width: 148px; */
  padding-top: 7px;
  grid-gap: -13px;
  gap: -13px;
  position: absolute;
  right: 160px;
  -webkit-text-stroke-width: 0px;
}
@keyframes slideInFromtop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
.detail-container-para {
  animation-duration: 0.5s;
  animation-timing-function: ease-in;
  animation-delay: 0s;
  animation-iteration-count: 0;
  animation-name: slideInFromtop;
}
.solution-detail-button {
  font-family: 'Open Sans' !important;
  font-style: normal;
  line-height: 22px;
  color: #fff;
  background-color: #eb4734;
  border-color: #eb4734;
  border-radius: 0px !important;
  height: 39px;
  font-weight: bold;
  font-size: 15px;
  width: 120px;
  outline: none !important;
  position: absolute;
  right: 80px;
  letter-spacing: 1px;
  font-weight: lighter;
}
.detail-arrow-img {
  margin-left: 6px;
  margin-right: -22px;
  margin-bottom: 3px;

  image-rendering: crisp-edges !important;
  transition: 3s !important;
}
.solution-para-text {
  color: #424242;
  line-height: 26px;
}
.detail-heading {
  font-weight: bolder;
  display: flex;
  left: 235px;
  top: 496px;
  color: #424242;
  /* padding-bottom: 24px; */
  -webkit-text-stroke-width: thin;
}
.grid div li {
  list-style-type: square;
  font-size: 18px;
  margin-right: 53px;
  color: #424242;
}
li::marker {
  color: #eb4734;
  font-size: 24px;
}
.grid {
  display: flex;
  flex-direction: row;
  gap: 35px;
}
.query-head {
  border-bottom: 5px solid #eb4734;
  width: 12rem;
  color: #424242;
  font-weight: bolder;
  font-size: 23px;
  margin: 2px;
  padding-bottom: 3px;
  -webkit-text-stroke-width: thin;
}
.analytics-head {
  border-bottom: 5px solid #eb4734;
  width: 13rem;
  color: #424242;
  font-weight: bolder;
  font-size: 23px;
  margin: 2px;
  padding-bottom: 3px;
  -webkit-text-stroke-width: thin;
}
.editor {
  color: #eb4734;
  cursor: pointer;
  font-weight: bolder;
}
.query-card {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.queryImg {
  width: 44px;
}
.runButton {
  font-size: 16px;
  color: #eb4734;
  font-weight: bold;
  padding-left: 3px;
}
.query-download .play {
  width: 22px;
}
.QueryDownload a {
  color: #eb4734;
  font-size: 15px;
  font-weight: bolder;
}
.container-card-meta {
  margin-bottom: -9px;
  display: flex;
  flex-direction: row;
  grid-gap: 0px;
  grid-gap: 0px;
  gap: 10px;
  border-bottom: 1px solid #8d8d8d;
  width: 100%;
  padding: 0px;
  margin-top: 18px;
}
.container-card-meta:last-child {
  border: none;
}
.sample-list {
  margin-top: 19px;
  margin-bottom: -26px;
  display: flex;
  flex-direction: row;
  grid-gap: 0px;
  grid-gap: 0px;
  gap: 10px;
  border-top: 1px solid #8d8d8d;
  width: 100%;
  padding: 6px;
}
.show-in-line {
  width: 78%;
}
.text-size {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #424242;
}
.meta-download-page {
  width: 54px;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 8px;
}

.meta-download {
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  gap: 8px;
}
.card-download-meta {
  width: 17.82px;
  cursor: pointer;
  padding-left: 0px !important;
}
.meta-download p {
  color: #eb4734;
  font-size: 15px;
  font-weight: bolder;
  text-align: center;
  cursor: pointer;
  /* padding-left: 10px; */
  margin: 2px 0 0 0 !important;
  -webkit-text-stroke-width: thin;
  letter-spacing: 0.6px;
}
.container {
  width: 100%;
  margin-top: 20px;
}
.query-download {
  display: flex;
  justify-content: space-between;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  color: #eb4734;
  font-weight: bolder;
  padding-left: 1.5rem;
  padding-top: 15px;
}
.back-button {
  padding-top: 9px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  cursor: pointer;
}
.show-grid {
  display: flex;
  flex-direction: row;
}
.meta-header-caption {
  font-size: 18px;
  color: #424242;
  padding: 5px;
}
.meta-head {
  border-bottom: 5px solid #eb4734;
  width: 14rem;
  color: #424242;
  font-weight: bolder;
  font-size: 23px;
  margin: 2px;
  padding-bottom: 3px;
  -webkit-text-stroke-width: thin;
}
.sample-img-solution img {
  width: 44px;
  margin-top: 4px;
  padding-right: 13px;
  margin-left: 26px;
  height: 30px;
}
.dive-deep {
  background-color: #404040;
  width: 100%;
}
.header-caption {
  font-size: 18px;
  color: #424242;
}
.dive-heading {
  border-bottom: 5px solid #eb4734;
  width: 9rem;
  color: #ffffff;
  font-size: 23px;
  font-weight: bolder;
  margin: 1px;
  padding-top: 16px;
  padding-bottom: 15px;
}
.dive-headerCaption {
  font-size: 16px;
  color: #ffffff;
  padding-top: 16px;
  padding-bottom: 16px;
}
.dive-download img {
  left: 60px;
  width: 29px;
  position: absolute;
  bottom: 14px;
}
.dive-download p {
  color: #eb4734;
  font-size: 15px;
  font-weight: bolder;
  cursor: pointer;
  position: absolute;
  left: 80px;
  bottom: 0px;
  padding-left: 20px;
}
.dive-card-img {
  width: 263.82px;
  height: 160px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
}
.show-in-line h5 {
  /* color: #424242; */
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
}
@media (max-width: 321px) {
  .trial-text {
    line-height: initial;
  }
  .trial-text-np {
    line-height: initial;
  }
  /* .detail-grid {
    padding-left: 25px;
  }
  .detail-heading {
     padding-left: 75px; 
    font-size: 22px;
  } */
  .detail-container-para {
    margin-top: 62px;
  }
  .detail-grid {
    padding-left: 0px !important;
  }
  .detail-heading {
    /* margin-top: 5px; */
    padding-left: 75px;
    font-size: 18px;
  }
  .solution-detail {
    right: 0px !important;
    width: 30%;
  }
  .solution-detail-button {
    font-size: 16px;
    width: 75px;
    right: 0px;
  }
  .grid ul {
    line-height: 21pt !important;
    word-spacing: 1px;
    column-count: 1 !important;
  }
  .card-dive-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .detail-img {
    /* margin-bottom: -125px; */
    width: 0% !important;
  }
}
@media only screen and (min-device-width: 322px) and (max-width: 376px) {
  .detail-container-para {
    margin-top: 62px;
  }
  .detail-heading {
    /* margin-top: 5px; */
    padding-left: 73px !important;
    font-size: 18px;
  }

  .trial-text {
    line-height: initial;
  }
  .trial-text-np {
    line-height: initial;
  }
  .detail-grid {
    padding-left: 0px !important;
  }
  .solution-detail {
    padding-top: 0px;
    right: 0px !important;
    width: 28%;
  }
  .solution-detail-button {
    font-size: 16px;
    width: 75px;
    right: 11px;
  }
  .grid ul {
    line-height: 21pt !important;
    word-spacing: 1px;
    column-count: 1 !important;
  }
  .card-dive-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
@media screen and (min-device-width: 376px) and (max-device-width: 500px) {
  .detail-grid {
    padding-left: 40px;
    /* margin-top: 68px; */
  }
  .react-multiple-carousel__arrow--left {
    left: calc(0% + -7px);
  }
  .grid ul {
    line-height: 21pt !important;
    word-spacing: 1px;
    column-count: 1 !important;
  }
  .detail-img {
    /* margin-bottom: -125px; */
    width: 10%;
  }
  .detail-heading {
    /* margin-top: 5px; */
    /* padding-left: 80px; */
    font-size: 22px;
  }
  .solution-detail-button {
    width: 90px;
    position: absolute;
    right: 15px !important;
  }
  .solution-detail {
    padding-top: 4px;
    width: 90px;
    position: absolute;
    right: 15px !important;
  }
  .text-size {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: #424242;
    padding-right: 10px;
  }
  .query-container {
    width: 100%;
    padding-right: 15px;
    margin-left: auto;
  }
  .card-dive-text {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
  }
  .trial-main {
    display: inline-table;
  }
  .trial-main-np {
    display: inline-table;
  }
  .trial-text {
    line-height: initial;
  }
  .trial-text-np {
    line-height: initial;
  }
  /* .card-query-style {
    padding: 0px !important;
  } */
}
@media screen and (min-width: 500px) and (max-width: 770px) {
  .detail-grid {
    padding-left: 0px;
    /* margin-top: 68px; */
  }
  .solution-type-home {
    height: 22px;
    background: #ebc259;
    width: 112px;
    margin-bottom: 7px;
    margin-left: 80px;
  }
  .dataset-type-home {
    height: 22px;
    background: #c7d6e3;
    width: 76px;
    margin-bottom: 7px;
    margin-left: 80px;
  }
  /* .card-query-style {
    padding: 0px !important;
  } */
  .detail-heading {
    /* margin-top: 5px; */
    padding-left: 80px;
    font-size: 30px;
  }
  .grid ul {
    column-count: 2 !important;
  }

  .detail-img img {
    width: 74.5px;
    /* margin-bottom: -75px; */
  }
  .card-dive-text {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
  }
  .solution-detail-button {
    position: absolute;
    right: 30px !important;
  }
  .solution-detail {
    padding-top: 5px;
    position: absolute;
    right: 70px !important;
  }
  .react-multiple-carousel__arrow--left {
    left: -1px !important;
  }
  .query-container {
    width: 100%;
    padding-right: 15px;
    margin-left: auto;
  }
  .trial-text {
    line-height: initial;
  }
  .trial-text-np {
    line-height: initial;
  }
  .solution-para-text {
    padding-left: 80px;
  }
}
@media screen and (min-device-width: 771px) and (max-device-width: 970px) {
  .react-multi-carousel-item,
  .react-multi-carousel-item--active {
    flex: 1 1 auto !important;
    position: relative !important;
    max-width: 315 !important;
  }
  .card-dive-text {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
  }
  .solution-detail {
    position: absolute;
    right: 70px !important;
  }
  .solution-detail-button {
    position: absolute;
    right: 18px !important;
  }
}

@media screen and (min-device-width: 970px) and (max-device-width: 1100px) {
  .react-multi-carousel-item,
  .react-multi-carousel-item--active {
    flex: 1 1 auto !important;
    position: relative !important;
    max-width: 330px !important;
  }
  .card-dive-text {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
  }
  .solution-detail {
    position: absolute;
    right: 85px !important;
  }
  .solution-detail-button {
    right: 45px;
  }
}
@media screen and (min-device-width: 1100px) and (max-device-width: 1300px) {
  .query-container,
  .detail-containers,
  .meta-container,
  .sample-container,
  .dive-container {
    max-width: 76% !important;
    padding-left: 50px !important;
  }
  .detail-grid {
    padding-left: 100px;
    /* margin-top: 68px; */
  }
  .grid ul {
    line-height: 21pt !important;
    word-spacing: 1px;
    column-count: 2 !important;
  }
  .solution-detail {
    position: absolute;
    right: 100px !important;
  }
  .solution-detail-button {
    right: 100px !important;
  }
  .react-multiple-carousel__arrow--left {
    left: calc(2% + 1px) !important;
  }
  .show-in-line {
    width: 100%;
  }
  .container-card-meta {
    width: 100%;
    margin-bottom: 0px;
  }
  .sample-list {
    width: 100%;
    margin-bottom: 0px;
  }
  .dive-card-img {
    width: 263.82px;
    height: 160px;
  }

  .card-control {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 4 !important;
    -webkit-box-orient: vertical !important;
  }
  .react-multi-carousel-item,
  .react-multi-carousel-item--active {
    flex: 1 1 auto !important;
    position: relative !important;
    max-width: 325px !important;
  }
  .download {
    margin-top: 15px;
  }
  .detail-heading {
    /* margin-top: -115px !important; */
    /* margin-left: 89px !important; */
  }
  .card-dive-text {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
  }
}

@media screen and (min-device-width: 1300px) and (max-device-width: 1920px) {
  .container-card-meta {
    width: 100%;
  }
  .sample-list {
    width: 100%;
  }
  .dive-card-img {
    width: 263.82px;
    height: 160px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .react-multi-carousel-item,
  .react-multi-carousel-item--active {
    flex: 1 1 auto !important;
    position: relative !important;
    width: 315px !important;
  }
  .card-download-meta,
  .card-download-para {
    /* padding-left: 10px !important; */
    margin: 0 !important;
  }
  .card-dive-text {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
  }
  .solution-detail-button {
    position: absolute;
    right: 160px;
  }
}
@media screen and (min-device-width: 1921px) and (max-device-width: 2550px) {
  .card-download-meta,
  .card-download-para {
    /* padding-left: 165px; */
    margin: 0 !important;
  }
  .react-multi-carousel-item,
  .react-multi-carousel-item--active {
    flex: 1 1 auto !important;
    position: relative !important;
    width: 325px !important;
  }
  .card-dive-text {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
  }
  .solution-detail-button {
    position: absolute;
    right: 260px;
  }
}
@media screen and (min-device-width: 1300px) and (max-device-width: 2550px) {
  .query-container,
  .detail-containers,
  .meta-container,
  .sample-container,
  .dive-container {
    max-width: 76% !important;
    padding-left: 60px !important;
  }

  .dive-container {
    margin-top: 61px !important;
  }
  /* .dive-download p {
    margin-top: 0px;
  } */
  .dive-card-img {
    width: 263.82px;
    height: 160px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  /* .card-query-style {
    min-width: 270px !important;
    max-width: 325px !important;
  } */
}

@media screen and (min-device-width: 2550px) and (max-device-width: 5500px) {
  .query-container,
  .detail-containers,
  .dive-container,
  .meta-container,
  .sample-container,
  .dive-container {
    max-width: 76% !important;
    padding-left: 20px !important;
  }
  .card-control {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 4 !important;
    -webkit-box-orient: vertical !important;
  }
  /* .dive-download p {
    margin-top: -6px;
    margin-left: 30px;
  }
  .dive-download img {
    margin-bottom: 0px;
    margin-left: 38px;
  } */
  .sample-list {
    margin-bottom: -26px;
    display: flex;
    flex-direction: row;
    grid-gap: 0px;
    grid-gap: 0px;
    gap: 0px;
    border-top: 1px solid #8d8d8d;
    max-width: 100% !important;
  }
  .solution-detail {
    right: 390px !important;
  }
  .solution-detail-button {
    right: 460px !important;
  }
  .card-download-meta,
  .card-download-para {
    /* padding-left: 240px; */
    margin: 0 !important;
  }
  .meta-download {
    padding-left: 10px;
  }
  .container-card-meta {
    width: 100%;
  }
  .sample-list {
    width: 100%;
  }
  .detail-heading {
    width: max-content;
  }
  .dive-card-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 15px !important;
    width: 263.82px;
    height: 160px;
  }
  .react-multi-carousel-item,
  .react-multi-carousel-item--active {
    flex: 1 1 auto !important;
    position: relative !important;
    width: 325px !important;
  }
  /* .card-query-style {
    max-width: 380px !important;
  } */
}
@media screen and (min-width: 2550px) {
  /* .solution-detail-button {
    right: 500px !important;
  } */
  .card-dive-text {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
  }
  .solution-detail {
    right: 500px !important;
  }
}
.react-multiple-carousel__arrow--left::before {
  margin-right: 125px !important;
}
.react-multiple-carousel__arrow--right::before {
  padding-left: 160px !important;
  content: '\e825' !important;
}
.carousel-dive-color .react-multiple-carousel__arrow::before {
  font-size: 32px !important;
  color: #fff !important;
  display: block !important;
  font-family: revicons;
}
.react-multiple-carousel__arrow::before {
  font-size: 32px !important;
  color: black !important;
  display: block !important;
  font-family: revicons;
}
.react-multiple-carousel__arrow {
  background-color: transparent !important;
  outline: none !important;
}
[dir='rtl'] .carousel-container {
  direction: ltr;
}
.react-multiple-carousel__arrow:hover {
  background-color: transparent !important;
  outline: none !important;
}
.react-multi-carousel-list {
  position: static !important;
}
.trial-main {
  background-color: #ffffff;
  text-align: center;
  padding-top: 82px;
  padding-bottom: 82px;
  width: 100%;
  height: 206px;
  line-height: 1pt;
}
.trial-heading {
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  color: #424242;
}
.trial-text {
  font-size: 18px;
  color: #424242;
}
.trial-link {
  font-size: 18px;
  font-weight: bold;
  color: #424242;
  text-decoration-line: underline !important;
  cursor: pointer;
}
.trail-main-np {
  background-color: #eb4734;
  text-align: center;
  padding-top: 82px;
  padding-bottom: 82px;
  width: 100%;
  height: fit-content;
  line-height: 1pt;
}
.trial-heading-np {
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  color: #fff;
}
.trial-text-np {
  font-size: 18px;
  color: #fff;
  padding-top: 10px;
  word-spacing: 2px;
  line-height: initial;
}
.trial-anchor {
  text-decoration: none;
  color: #424242;
}
.trial-anchor:hover {
  text-decoration: none;
  color: #424242;
}
.card-query-style {
  background: #ffffff;
  margin-left: 15px !important;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 12%);
  border-radius: 2px;
  cursor: pointer;
  min-width: 270px;
  max-width: 310px !important;
  min-height: 380px;
  max-height: 380px;
  padding-top: 23px;
  padding-left: 40px;
  padding-right: 40px;
}

.react-multi-carousel-item,
.react-multi-carousel-item--active {
  flex: 1 1 auto !important;
  position: relative !important;
  width: 330px !important;
}

/* Solution Purchase dialog */

.purchaseDialog {
  font-weight: bolder;
  font-size: 30px;
  color: #424242;
  width: 75vw !important;
  margin: 10vh auto !important;
}
.p-6 {
  padding: 6%;
  -webkit-text-stroke-width: thin;
  margin-left: 35px;
}
.btn-close {
  float: right;
}

.btn-close:active,
.btn-close:hover,
.btn-close:focus {
  border: none !important;
  text-decoration: none;
  background-color: white !important;
  color: #000000;
}

.try-button {
  width: 65.11px;
  /* height: 52.03px; */
  font-size: 16px;
  font-weight: bold;
  border-radius: 0px;
  /* font-family: 'open-sans'; */
  font-style: normal;
  align-items: center;
  text-align: center;
  background-color: #eb4734;
  cursor: pointer;
  outline: none !important;
  margin: 20px;
}
.card-catalog-text {
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 4 !important;
  -webkit-box-orient: vertical !important;
}
.card-catalog-style {
  background: #ffffff;
  margin-left: 15px !important;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 12%);
  border-radius: 2px;
  min-width: 270px;
  max-width: 310px !important;
  min-height: 380px;
  max-height: 380px;
  padding: 23px 20px 5px 20px;
}
.search-catalog {
  margin-top: 20px;
  margin-bottom: 10px;
  min-width: 274px;
  max-width: 314px;
  min-height: 38px;
  max-height: 38px;
  outline: none;
  border: 1px solid #8d8d8d;
  padding-left: 12px;
}
.no-search {
  display: block;
  margin: 0px auto;
  padding-top: 32px;
}
.search-img {
  width: 20.49px;
  position: relative;
  margin-left: -30px;
  /* margin-top: 10px; */
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
.container-solutionpack {
  margin: 0px !important;
  padding: 35px 35px 70px 35px !important;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  max-width: -webkit-fill-available !important;
  min-height: 464px;
}
.heading-solution {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 50px;
  color: #424242;
  -webkit-text-stroke-width: thin;
}
.solutionPack-column {
  padding-top: 15.75px;
  padding-bottom: 15.75px;
  border-bottom: 1px solid #666666;
}
.solutionPack-row {
  display: flex;
  gap: 30px;
  /* align-items: center; */
}
.solutionPack-row:hover {
  cursor: pointer;
}
.solutionPack-row-try {
  gap: 35.58px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.solutionPack-row:hover {
  cursor: pointer;
}
.solutionPack-row-try {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.rows {
  padding-bottom: 30px;
  border-bottom: 1px solid #666666;
  margin-bottom: 10px;
}
.rows:last-child {
  border: none;
}
.button-tryClick {
  cursor: pointer;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #eb4734;
}
.click-solution {
  cursor: pointer;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #eb4734;
}
.solution-heading {
  margin-top: 30px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 25px;
  color: #424242;
  -webkit-text-stroke-width: thin;
}
.solution-button {
  font-weight: bold !important;
  border-style: none !important;
  width: auto;
  margin-top: 30px;
  border-radius: 0px !important;
  background: #eb4734 !important;
  -webkit-text-stroke-width: initial !important;
}
.row-Specification {
  gap: 8px !important;
  justify-content: space-between !important;
  flex-wrap: nowrap !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
@media only screen and (max-width: 1265px) {
  .row-Specification {
    gap: 18px !important;
  }
  .container-solutionpack {
    padding: 35px 54px 70px 54px !important;
  }
  .interest-licence-try {
    width: 60%;
  }
}
@media only screen and (min-width: 1920px) {
  .row-Specification {
    gap: 80px !important;
  }
  .rows {
    padding-bottom: 23px;
  }
}
@media only screen and (min-width: 2773px) {
  .rows {
    padding-top: 25px !important;
  }
  .col-subs {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

@media only screen and (min-width: 1440px) {
  .rows {
    padding-bottom: 25px !important;
  }
  .col-subs {
    padding-right: 6px !important;
    padding-left: 6px !important;
  }
  .col-nO-sub {
    max-width: 65% !important;
  }
  .solution-more {
    column-gap: 80px !important;
  }
}

.propertyReport {
  padding: 25px;
  margin-top: 10px;
  margin-bottom: 10px;
  background: #ffffff !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15) !important;
  width: 100%;
}
.api-row {
  gap: 90px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.property-reports {
  flex-wrap: 'nowrap';
  gap: 30px;
}
.upgrade-data {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 50px;
  color: #424242;
  -webkit-text-stroke-width: thin;
}
.interest-licence {
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 28px !important;
  color: #424242 !important;
}
.solution-center {
  margin: 0;
  display: flex;
  align-items: center;
}
.interest-licence-try {
  width: 70%;
}
.licence {
  margin: 20px 0px 90px 0px !important;
}
.solutionPackPropertyImg {
  min-width: 242px !important;
  max-width: 474px !important;
  transform: translateY(23%);
}
.scrollable {
  overflow: auto;
  height: 250px;
}
.api-properties {
  font-size: 32px !important;
  line-height: 50px !important;
  color: #424242 !important;
}
.space-abv {
  padding-top: 49px;
}
.button-expand {
  margin: 0;
  width: 150px;
  margin-top: -12.33px;
}
.solution-more {
  column-count: 2;
}
.sql-editor {
  width: 35% !important;
}
.learn-more-help {
  display: flex;
  align-items: end;
  justify-content: space-between;
}
.button-copy-image {
  width: 150px !important;
}
.upper-api-button {
  padding-bottom: '54px';
  padding-top: '17px';
}
@media only screen and (max-width: 800px) {
  .solution-more {
    column-count: 1;
  }
  .sql-editor {
    width: 100% !important;
  }
  .upper-api-button {
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 600px) {
  .button-copy-image {
    position: absolute !important;
    margin-top: 170px !important;
  }
  .upper-api-button {
    padding-top: 150px !important;
    padding-bottom: 50px !important;
  }
  .button-expand {
    width: 190px !important;
  }
  .row-Specification {
    justify-content: space-around !important;
    width: 90% !important;
    display: block !important;
  }
  .property-reports {
    flex-wrap: wrap;
  }
  .container-solutionpack {
    padding: 15px !important;
  }
  .solutionPackPropertyImg {
    transform: translateY(40%);
  }
}
@media only screen and (min-width: 1200px) {
  .property-reports {
    flex-wrap: nowrap !important;
  }
  .propertyReport {
    width: 50%;
  }
}
@media only screen and (max-width: 1220px) {
  .api-row {
    gap: 20px;
  }
}
@media only screen and (max-width: 1024px) {
  .solutionPack-row-try {
    gap: 20px;
  }
  .container-solutionpack {
    padding: 35px !important;
  }
}
.multiSelectContainer {
  min-width: 262px !important;
  max-width: 97% !important;
}
.select-multi {
  margin: 20px !important;
  position: relative !important;
  min-height: 38px;
  /* max-height: 38px; */
  max-height: fit-content !important;
  background: #ffffff !important;
  /* min-width: 290px !important; */
  /* max-width: 70% !important; */
  border: 1px solid #8d8d8d !important;
}

.select-multi .searchBox {
  margin-top: -3px;
}
.multiSelectContainer ul {
  padding-bottom: 10px !important;
  overflow: hidden;
  min-height: 35px !important;
  max-height: fit-content !important;
}
.searchWrapper {
  border: none !important;
  max-width: 100%;
  display: flex !important;
  flex-wrap: wrap !important;
  position: relative !important;
  padding: 0 !important;
  padding-left: 10px !important;
  padding-top: 5px !important;
}

.highlightOption {
  background: #ffffff !important;
  color: #424242 !important;
}
.search-select {
  display: flex;
  /* flex-wrap: wrap; */
}
.optionContainer .checkbox {
  accent-color: #eb4734;
}
.searchBox {
  font-family: inherit !important;
  font-size: 18px !important;
  line-height: inherit !important;
  /* min-width: 50% !important; */
  max-width: max-content !important;
}

/* .icon_down_dir {
  top: 0 !important;
} */
.option:hover {
  background-color: transparent !important;
  color: #424242 !important;
}
.optionContainer {
  height: fit-content;
}

.multiSelectContainer li {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  height: 25px;
}
.icon_down_dir:before {
  content: '' !important;
  display: none !important;
}
.icon_down_dir {
  position: absolute !important;
  right: 8px !important;
  top: 5% !important;
  transform: none !important;
}
/* @media screen and (max-width: 600px) { */
/* .search-select {
    display: block;
  } */
/* .select-multi {
    position: relative !important;
  }
  .select-multi {
    margin: 0px !important;
  }
} */
@media screen and (max-width: 1065px) {
  .search-select {
    display: block;
  }
  /* .select-multi {
    position: relative !important;
  }
  .select-multi {
    margin: 0px !important;
  } */
  .multiSelectContainer {
    margin-left: 0 !important;
  }
  .search-catalog {
    width: 97%;
    max-width: inherit;
    min-width: inherit;
  }
  .data-Element-select {
    padding-left: 0px !important;
  }
  .industries_select {
    margin-top: -10px;
  }
}

.purchaseDialogMetaData {
  width: 27vw;
  margin-top: 10%;
}
@media only screen and (max-width: 650px) {
  .purchaseDialogMetaData {
    width: 80%;
  }
  .show-in-line {
    width: 47% !important;
  }
}
@media screen and (min-device-width: 651px) and (max-device-width: 1023px) {
  .show-in-line {
    width: 60% !important;
  }
}
@media screen and (min-device-width: 1024px) and (max-device-width: 1300px) {
  .show-in-line {
    width: 75% !important;
  }
}
.meta-overlay {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #424242;
}

.meta-button {
  margin-top: -28px;
  margin-right: -25px;
}

.solution-pack-img {
  width: 50px !important;
  height: 50px !important;
}
.show-oppo {
  flex-direction: row-reverse !important;
}
.detail-bgs {
  display: inline-block;
  width: 98%;
  background: #ffffff;
  padding: 24px 37px;
  margin-top: 17px;
  margin-bottom: 36px;
  margin-left: 12px;
  border-radius: 0.25rem;
  color: #424242;
}

.data_items_list {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 10px;
  color: #eb4734;
  /* flex: 233px; */
  /* flex-grow: 1; */
  width: 33%;
}
.data_link_cursor {
  cursor: pointer;
}

.detail-containers_data {
  display: flex;
  padding-top: 15px;
  flex-wrap: wrap;
  /* column-gap: 50px; */
}

.lists_editor {
  display: flex;
  gap: 30px;
}

.lists_editor li::marker {
  color: #424242;
  font-size: small;
}

@media only screen and (max-width: 1024px) {
  .checkbox-main {
    padding-left: 0px !important;
  }
  .detail-containers_data {
    flex-direction: column;
  }
  .data_items_list {
    width: 100%;
  }
  .data-Element-select div:first-child {
    margin-left: 0px !important;
  }
}

.select-multis {
  padding-left: 20px;
}
/* .class div:first-child */
.data-Element-select .select-multi {
  margin-left: 23px !important;
}
.data-Element-select .searchBox {
  padding-left: 15px;
}
.isPurchasedCatalog {
  display: flex;
  align-content: end;
  align-items: baseline;
  justify-content: space-between;
  height: 65px;
}

.solutionLicensed {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;

  color: #424242;
}
.solutionTicked {
  width: 15.5px;
  height: 13px;
  margin-left: 10.5px;
}

.solutionWatchVedio {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;

  /* or 162% */
  cursor: pointer;
  /* button red */
  color: #eb4734;
}
.modal-dialog {
  max-width: 900px !important;
  margin: 1.75rem auto;
}
.modal-backdrop {
  text-align: end !important;
}

.closeButton {
  position: absolute;
  margin: 18px;
  cursor: pointer;
  z-index: 1000;
  right: 0;
}

.video-height {
  height: 100% !important;
  margin-bottom: -9px !important;
}
@media only screen and (min-width: 1440px) {
  .modal-dialog {
    max-width: 80% !important;
    margin: 1.75rem auto;
  }
  /* .multiSelectContainer {
    max-width: 100% !important;
  } */
}

.directIntegrationCopyButton {
  min-width: 72px;
  float: right;
  color: #fff !important;
  text-transform: capitalize !important;
  outline: none !important;
}

.copy-msg-direct {
  /* display: inline-block; */
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  font-weight: 100 !important;
  color: #797979;
  padding: 10px;
  box-sizing: border-box;
  max-width: 350px;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 25%);
  position: absolute;
  z-index: 36px;
  margin-left: 23px;
  margin-top: -30px;
  right: 129px;
  background-color: white;
}
[data-reach-menu-item][data-selected] {
  background: #eb4734;
}

.export-btn {
  background: transparent;
  outline: none;
}
[aria-label='Download'] {
  color: #eb4734 !important;
}
[data-reach-menu-button]:hover {
  text-decoration: none;
  outline: none;
  border: 1px solid transparent !important;
}

[data-reach-menu-item][data-selected] {
  background: #eb4734;
}

.export-btn {
  background: transparent;
  outline: none;
}
[aria-label='Download'] {
  color: #eb4734 !important;
}
[data-reach-menu-button]:hover {
  text-decoration: none;
  outline: none;
  border: 1px solid transparent !important;
}

[data-reach-menu-item][data-selected] {
  background: #eb4734;
}

.export-btn {
  background: transparent;
  outline: none;
}
[aria-label='Download'] {
  color: #eb4734 !important;
}
[data-reach-menu-button]:hover {
  text-decoration: none;
  outline: none;
  border: 1px solid transparent !important;
}

.view-download-button {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.view-download-meta {
  width: 25px;
  cursor: pointer;
}

.display-none-view {
  visibility: hidden;
}

.solution-type-home {
  height: 22px;
  background: #ebc259;
  width: 112px;
  margin-bottom: 7px;
}
.dataset-type-home {
  height: 22px;
  background: #c7d6e3;
  width: 76px;
  margin-bottom: 7px;
}
.solution-type {
  position: absolute;
  top: 9px;
  right: 0;
  height: 22px;
  background: #ebc259;
  width: 112px;
}
.dataset-type {
  position: absolute;
  top: 9px;
  right: 0;
  height: 22px;
  background: #c7d6e3;
  width: 76px;
}

.solution-type-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #424242;
}
@media screen and (min-width: 370px) and (max-width: 499px) {
  .delivery-type-main {
    padding-left: 10px !important;
    flex-direction: column !important;
  }
}
@media screen and (min-width: 500px) and (max-width: 770px) {
  .delivery-type-main {
    padding-left: 40px !important;
  }
}
@media screen and (min-width: 771px) and (max-width: 1023px) {
  .delivery-type-main {
    padding-left: 30px !important;
  }
}
.delivery-type-main {
  display: flex;
  gap: 4px;
  padding-left: 95px;
}

.bulk {
  width: 50px;
  height: 21px;
  background: #477a99;
}
.cloud {
  width: 65px;
  height: 21px;
  background: #e16d26;
}
.api {
  width: 41px;
  height: 21px;
  background: #8a649e;
}
.solutionDetail-delivery {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #ffffff;
}

.checkbox-main {
  display: flex;
  gap: 30px;
  padding-left: 25px;
  margin-top: 20px;
}

.checkbox-sc {
  width: 23px;
  height: 23px;
  left: 1014px;
  top: 254px;
  margin-top: 7px;
  margin-right: 0 !important;
  background: #ffffff;
  border: 1px solid #8d8d8d;
  accent-color: rgb(235, 71, 52);
  cursor: pointer;
}

.checkbox-label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  /* or 144% */
  color: #000000;
  cursor: pointer;
}

.checkbox-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
}

.wrap-check {
  display: -webkit-inline-box;
}

.propertyNav {
  width: 140px;
  height: 21px;
  background: #a00c00;
}

/* Password change */

.input_labels {
  color: #000000;
  font-size: 15px;
  font-weight: 500px;
}

.success_case {
  position: relative;
  /* padding-left: 20px; */
  text-align: start;
  font-size: 12px;
  color: green;
  margin: 0px !important;
}

.error_case {
  position: relative;
  /* padding-left: 20px; */
  text-align: start;
  font-size: 12px;
  color: red;
  margin: 0px;
}

.success_case::before {
  position: relative;
  content: '✔';
  margin-right: 12px;
}

.error_case::before {
  position: relative;
  margin-right: 8px;
  top: 2px;
  content: url(/assets/images/warning_icon.png);
}

.success-msg {
  color: #000000;
}

.pass-container {
  margin-top: 5px !important;
  margin-bottom: 10px !important;
}

.passwordChange-dialogueBox {
  margin: 0 auto;
}
/* End of Password change */
/* Error Component CSS Starts here */

.err-main-container {
  padding-top: 50px;
  padding-left: 50px;
}

.err-main-heading {
  font-weight: 700;
  font-size: 24px;
  font-family: 'Open Sans';
  color: black;
  line-height: 42px;
}

.err-sub-heading {
  margin: 10% 0% 10% 0% !important;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Open Sans';
  color: black;
  line-height: 28px;
}

/* End of Error Component */
