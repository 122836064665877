@keyframes attention {
  55% {
    box-shadow: 0 0 0px red;
  }
  70% {
    box-shadow: 0 0 9px red;
  }
  85% {
    box-shadow: 0 0 0px red;
  }
}

.attention {
  animation-name: attention;
  animation-duration: 6s;
  animation-iteration-count: infinite;
}
