.toolbar {
  height: 30px;
  border-bottom: 1px solid #ccc;
  background-color: #f4f4f4;
  /* line-height: 30px; */
  padding-left: 4px;
  display: flex;
  align-items: center;
}

.toolbarItem {
  padding-right: 48px;
  padding-left: 4px;
}

.iconLinkWrapper {
  height: 30px;
  display: inline-flex;
  align-items: center;
  line-height: 1;
}

.iconLink {
  margin-left: 16px;
  display: inline-flex;
  align-items: center;
  line-height: 1;
}

.exportButton {
  position: absolute;
  right: 0;
  top: -3px;
}

.informationIcon {
  width: 18px;
  position: relative;
  color: #ff3516;
  bottom: -3px;
}

.tooltip {
  white-space: pre-line;
  border: 0px;
}
.tooltip:hover {
  border: 0px;
  cursor: inherit;
  background-color: unset;
}
