.btn {
  line-height: 1.499;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.065);
  cursor: pointer;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 0 10px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: rgb(217, 217, 217);
  border-top-color: rgb(217, 217, 217, 0.5);
  border-left-color: rgb(217, 217, 217, 0.5);
}

.btn:hover,
.btn:focus {
  color: var(--primary-color);
  background-color: #fff;
  box-shadow: 0 1px 0 var(--primary-color-30);
  border: 2px solid var(--primary-color);
  /* padding adjustment prevents added border from shifting text */
  padding: 0 9px;
}
@media (min-width: 990px) and (max-width: 1288px) {
  .btn:hover,
  .btn:focus {
    padding: 0 8.6px;
  }
}
.btn .headername {
  padding-left: 24px !important;
}
.btn:active {
  box-shadow: none;
  background-color: #ebebeb;
  color: var(--primary-dark-color);
}

.btn:focus {
  outline: 0;
}

.primary {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 0 rgb(9, 100, 185, 0.5);
}

.primary:hover,
.primary:focus {
  color: #fff;
  background-color: var(--primary-color);
  border-color: transparent;
}

.primary:active {
  color: #fff;
  background-color: var(--primary-dark-color);
  border-color: var(--secondary-dark-color);
}

.danger {
  color: var(--secondary-color);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.danger:hover {
  color: #fff;
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.danger:focus {
  color: var(--secondary-color);
  background-color: #fff;
  border-color: var(--secondary-color);
}

.danger:active {
  color: #fff;
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.btn:disabled,
.btn[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #eee;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.065);
  border: 1px solid transparent;
  padding: 0 10px;
  border-color: #d9d9d9;
}

.ghost {
  color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 1px 0 transparent;
  border-color: transparent;
  background-color: transparent;
}

.ghost:hover,
.ghost:focus {
  color: rgba(255, 255, 255, 0.85);
  background-color: transparent;
}

.ghost:active {
  color: rgba(255, 255, 255, 1);
  background-color: transparent;
}

.ghost:disabled,
.ghost[disabled] {
  border: none;
  color: rgba(255, 255, 255, 0.55);
}

.headerbutton {
  color: #c4c4c4;
  opacity: 0.75;
  box-shadow: none;
  outline: transparent;
  border-color: transparent;
  height: 41px;
  margin-right: 15px;
  font: 16px 'Open Sans', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
  /* margin-top: -17px;
  margin-bottom: -17px; */
  background-color: #1b1b1b;
  min-width: 115px;
  border-radius: 2px;
  font-weight: 700;
}
.headerbutton:first-child {
  min-width: 90px;
}

.headerbutton:hover,
.headerbutton:focus {
  color: #fff;
  opacity: 1;
  box-shadow: 0 0;
  /* padding: 0 9px !important; */
  border: 2px solid #1b1b1b;
  background-color: #1b1b1b;
  /* transition: ease 0.3s; */
}

.headerbutton.active,
.headerbutton:active {
  color: #fff;
  opacity: 1;
  box-shadow: 0 0;
  padding: 0 9px;
  border: 2px solid #1b1b1b;
  background-color: #1b1b1b;
  text-decoration: underline;
  transition: ease 0.3s;
}

.subheaderbutton {
  color: #c4c4c4;
  opacity: 0.75;
  box-shadow: none;
  outline: transparent;
  border-color: transparent;
  /* height: 38px; */
  margin-right: 10px;
  /* margin-top: -8px;
  margin-bottom: -8px; */
  background-color: #4f4f4f;
  min-width: 115px;
  border-radius: 2px;
  font: 14px 'Open Sans', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
  font-weight: 700;
}

.subheaderbutton:hover,
.subheaderbutton:focus {
  color: #fff;
  opacity: 1;
  box-shadow: 0 0;
  border: 2px solid #4f4f4f;
  background-color: #4f4f4f;
  transition: ease 0.3s;
  padding: 0 9px;
}

.subheaderbutton.active,
.subheaderbutton:active {
  color: #fff;
  opacity: 1;
  box-shadow: 0 0;
  border: 2px solid #4f4f4f;
  background-color: #4f4f4f;
  transition: ease 0.3s;
  padding: 0 9px;
  text-decoration: underline;
}

.drawerbutton {
  color: #fff;
  box-shadow: none;
  outline: transparent;
  border-color: transparent;
  margin-right: 8px;
  background-color: #337ab7;
  min-width: 80px;
  border-radius: 2px;
  font: 14px 'Open Sans', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
  position: inherit;
  top: 5px;
  right: 5px;
}

.drawerbutton:hover,
.drawerbutton:focus {
  color: #fff;
  box-shadow: 0 0;
  border: 2px solid #337ab7;
  background-color: #337ab7;
  transition: ease 0.3s;
  padding: 0 5px;
}

.drawerbutton.active,
.drawerbutton:active {
  color: #fff;
  box-shadow: 0 0;
  border: 2px solid #337ab7;
  background-color: #337ab7;
  transition: ease 0.3s;
  padding: 0 5px;
}
