@import url(https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css);
/*!
 * Reset css taken from:
 *
 * antd v3.16.5
 * 
 * Copyright 2015-present, Alipay, Inc.
 * All rights reserved.
 *       
 */
html,
body {
  width: 100%;
  height: 100%;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC',
    'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-variant: tabular-nums;
  line-height: 1.5;
  background-color: #fff;
  font-feature-settings: 'tnum', "tnum";
}
[tabindex='-1']:focus {
  outline: none !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #1890ff;
  /* color: #00b7ff; */
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #40a9ff;
}
a:active {
  color: #096dd9;
}
a:active,
a:hover,
a:focus {
  text-decoration: none;
  outline: 2px solid #40a9ff;
}
a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none;
}
pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier,
    monospace;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
figure {
  margin: 0 0 1em;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  padding: 0.2em;
  background-color: #feffe6;
}
::selection {
  color: #fff;
  background: #1890ff;
}

/* variables
============================================================================ */
:root {
  /* 
    These REFERENCE are various colors that were sprinkled throughout
    They are kept around for reference until colors settle
  */
  --REFERENCE-link-color: #00b7ff;
  --REFERENCE-button-border-color: #40a9ff;
  --REFERENCE-button-active-color: #096dd9;
  --REFERENCE-primary-color: #1890ff;

  --primary-color: hsl(209, 100%, 55%);
  --primary-dark-color: hsl(209, 100%, 42%);
  --primary-light-color: hsl(209, 100%, 63%);
  --primary-darkest-color: hsl(209, 50%, 25%);

  --primary-color-30: rgba(24, 143, 255, 0.3);
  --primary-color-90: rgba(24, 144, 255, 0.9);

  --secondary-color: rgb(247, 10, 10);
  --secondary-dark-color: hsl(323, 100%, 50%);
  --secondary-color-90: rgba(250, 50, 173, 0.9);
  --secondary-color-75: rgba(250, 50, 173, 0.75);
  --secondary-color-30: rgba(250, 50, 173, 0.3);

  --border: 1px solid rgba(0, 0, 0, 0.15);

  --box-shadow-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  --box-shadow-2: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

/* core styles
============================================================================ */
a {
  color: hsl(209, 100%, 55%);
  color: var(--primary-color);
}
a:hover,
a:active,
a:focus {
  text-decoration: none;
}

button,
select {
  text-transform: none;
}

button,
input {
  overflow: visible;
}

/* reach ui 
============================================================================ */
[data-reach-tooltip] {
  z-index: 9000 !important;
}

[data-reach-dialog-overlay] {
  z-index: 9000 !important;
}

[data-reach-dialog-content] {
  z-index: 9000 !important;
}

[data-reach-dialog-overlay] {
  background: rgba(0, 0, 0, 0.4) 100%;
  background: radial-gradient(
    at 50% 300px,
    rgba(120, 120, 120, 0.2) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
}

[data-reach-menu-item][data-selected] {
  background: hsl(209, 100%, 55%);
  background: var(--primary-color);
  color: white;
  outline: none;
}

/* 
  This helps fix weird pop in animation for icon menu button 
  (It also looks nice)
*/
@-webkit-keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.slide-down[data-reach-menu-list],
.slide-down[data-reach-menu-items] {
  border-radius: 2px;
  -webkit-animation: slide-down 0.1s ease;
          animation: slide-down 0.1s ease;
}

/*  Utilities
============================================================================ */
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flex-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.bg-error {
  background-color: rgba(250, 50, 173, 0.75);
  background-color: var(--secondary-color-75);
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

/* Might be trying out more of just plain css, less of css.modules */
.sp-error-block {
  height: 100%;
  width: 100%;

  display: -ms-flexbox;

  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  overflow: scroll;

  font-size: 15px;
  padding: 24px;
  text-align: center;
  color: #ff3516;
}

.sp-info-block {
  height: 100%;

  display: -ms-flexbox;

  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;

  font-size: 1.3rem;
  padding: 24px;
  text-align: center;
}

.spinning {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

/* hide tauchart's ui for export options */
.tau-chart__export {
  display: none;
}

/* react splitpane resizer */
.Resizer.vertical:hover {
  border-left: 5px solid var(--primary-color-90);
  border-right: 5px solid var(--primary-color-90);
}

/* react splitpane resizer */
.Resizer.horizontal:hover {
  border-top: 5px solid var(--primary-color-90);
  border-bottom: 5px solid var(--primary-color-90);
}

.Resizer:hover {
  transition: all 0.3s ease;
}

/* QueryResultDataTable react-window/react-draggable implementation */
.DragHandle {
  -ms-flex: 0 0 16px;
      flex: 0 0 16px;
  z-index: 2;
  cursor: col-resize;
  color: rgba(0, 0, 0, 0.2);
}
.DragHandle:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.DragHandleActive,
.DragHandleActive:hover {
  color: rgba(0, 0, 0, 0.6);
  z-index: 3;
}

.DragHandleIcon {
  -ms-flex: 0 0 12px;
      flex: 0 0 12px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}

/* Missing Ace diff editor style for highlighting code changes */
.codeMarker {
  background: #fff677;
  position: absolute;
  z-index: 20;
}

.message_message__2aY5k {
  box-shadow: var(--box-shadow-1);
  position: fixed;
  color: #fff;
  background-color: green;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  padding: 4px;
  /* top: 0px; */
  padding: 10px 15px 10px 15px;
  right: 40%;
  width: auto;
  z-index: 9001 !important;
  /* border-radius: 4px; */
  text-align: center;
}

.message_error__3sZ3a {
  background-color: #eb4734;
}

.Button_btn__2jK9g {
  line-height: 1.499;
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.065);
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  height: 32px;
  padding: 0 10px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: rgb(217, 217, 217);
  border-top-color: rgb(217, 217, 217, 0.5);
  border-left-color: rgb(217, 217, 217, 0.5);
}

.Button_btn__2jK9g:hover,
.Button_btn__2jK9g:focus {
  color: var(--primary-color);
  background-color: #fff;
  box-shadow: 0 1px 0 var(--primary-color-30);
  border: 2px solid var(--primary-color);
  /* padding adjustment prevents added border from shifting text */
  padding: 0 9px;
}
@media (min-width: 990px) and (max-width: 1288px) {
  .Button_btn__2jK9g:hover,
  .Button_btn__2jK9g:focus {
    padding: 0 8.6px;
  }
}
.Button_btn__2jK9g .Button_headername__3eB3x {
  padding-left: 24px !important;
}
.Button_btn__2jK9g:active {
  box-shadow: none;
  background-color: #ebebeb;
  color: var(--primary-dark-color);
}

.Button_btn__2jK9g:focus {
  outline: 0;
}

.Button_primary__f5uu3 {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 0 rgb(9, 100, 185, 0.5);
}

.Button_primary__f5uu3:hover,
.Button_primary__f5uu3:focus {
  color: #fff;
  background-color: var(--primary-color);
  border-color: transparent;
}

.Button_primary__f5uu3:active {
  color: #fff;
  background-color: var(--primary-dark-color);
  border-color: var(--secondary-dark-color);
}

.Button_danger__ClkBS {
  color: var(--secondary-color);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.Button_danger__ClkBS:hover {
  color: #fff;
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.Button_danger__ClkBS:focus {
  color: var(--secondary-color);
  background-color: #fff;
  border-color: var(--secondary-color);
}

.Button_danger__ClkBS:active {
  color: #fff;
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.Button_btn__2jK9g:disabled,
.Button_btn__2jK9g[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #eee;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.065);
  border: 1px solid transparent;
  padding: 0 10px;
  border-color: #d9d9d9;
}

.Button_ghost__2TMAY {
  color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 1px 0 transparent;
  border-color: transparent;
  background-color: transparent;
}

.Button_ghost__2TMAY:hover,
.Button_ghost__2TMAY:focus {
  color: rgba(255, 255, 255, 0.85);
  background-color: transparent;
}

.Button_ghost__2TMAY:active {
  color: rgba(255, 255, 255, 1);
  background-color: transparent;
}

.Button_ghost__2TMAY:disabled,
.Button_ghost__2TMAY[disabled] {
  border: none;
  color: rgba(255, 255, 255, 0.55);
}

.Button_headerbutton__2uDCM {
  color: #c4c4c4;
  opacity: 0.75;
  box-shadow: none;
  outline: transparent;
  border-color: transparent;
  height: 41px;
  margin-right: 15px;
  font: 16px 'Open Sans', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
  /* margin-top: -17px;
  margin-bottom: -17px; */
  background-color: #1b1b1b;
  min-width: 115px;
  border-radius: 2px;
  font-weight: 700;
}
.Button_headerbutton__2uDCM:first-child {
  min-width: 90px;
}

.Button_headerbutton__2uDCM:hover,
.Button_headerbutton__2uDCM:focus {
  color: #fff;
  opacity: 1;
  box-shadow: 0 0;
  /* padding: 0 9px !important; */
  border: 2px solid #1b1b1b;
  background-color: #1b1b1b;
  /* transition: ease 0.3s; */
}

.Button_headerbutton__2uDCM.Button_active__23zfh,
.Button_headerbutton__2uDCM:active {
  color: #fff;
  opacity: 1;
  box-shadow: 0 0;
  padding: 0 9px;
  border: 2px solid #1b1b1b;
  background-color: #1b1b1b;
  text-decoration: underline;
  transition: ease 0.3s;
}

.Button_subheaderbutton__1kvqK {
  color: #c4c4c4;
  opacity: 0.75;
  box-shadow: none;
  outline: transparent;
  border-color: transparent;
  /* height: 38px; */
  margin-right: 10px;
  /* margin-top: -8px;
  margin-bottom: -8px; */
  background-color: #4f4f4f;
  min-width: 115px;
  border-radius: 2px;
  font: 14px 'Open Sans', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
  font-weight: 700;
}

.Button_subheaderbutton__1kvqK:hover,
.Button_subheaderbutton__1kvqK:focus {
  color: #fff;
  opacity: 1;
  box-shadow: 0 0;
  border: 2px solid #4f4f4f;
  background-color: #4f4f4f;
  transition: ease 0.3s;
  padding: 0 9px;
}

.Button_subheaderbutton__1kvqK.Button_active__23zfh,
.Button_subheaderbutton__1kvqK:active {
  color: #fff;
  opacity: 1;
  box-shadow: 0 0;
  border: 2px solid #4f4f4f;
  background-color: #4f4f4f;
  transition: ease 0.3s;
  padding: 0 9px;
  text-decoration: underline;
}

.Button_drawerbutton__3Z1n_ {
  color: #fff;
  box-shadow: none;
  outline: transparent;
  border-color: transparent;
  margin-right: 8px;
  background-color: #337ab7;
  min-width: 80px;
  border-radius: 2px;
  font: 14px 'Open Sans', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
  position: inherit;
  top: 5px;
  right: 5px;
}

.Button_drawerbutton__3Z1n_:hover,
.Button_drawerbutton__3Z1n_:focus {
  color: #fff;
  box-shadow: 0 0;
  border: 2px solid #337ab7;
  background-color: #337ab7;
  transition: ease 0.3s;
  padding: 0 5px;
}

.Button_drawerbutton__3Z1n_.Button_active__23zfh,
.Button_drawerbutton__3Z1n_:active {
  color: #fff;
  box-shadow: 0 0;
  border: 2px solid #337ab7;
  background-color: #337ab7;
  transition: ease 0.3s;
  padding: 0 5px;
}

.Input_input__2-5S2 {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.Input_input__2-5S2:focus {
  border: 2px solid var(--primary-color);
  /* padding adjustment prevents added border from shifting text */
  padding-left: 10px;
  padding-right: 10px;
  outline: 0;
}

.Input_input__2-5S2:hover {
  border: 2px solid var(--primary-color);
  /* padding adjustment prevents added border from shifting text */
  padding-left: 10px;
  padding-right: 10px;
}

.Input_danger__KRM78 {
  border-color: #f42713;
  /* box-shadow: inset 0 1px 1px #f42713; */
}

.Input_danger__KRM78:focus {
  border: 1px solid #f42713;
  outline: 0;
}

.Input_danger__KRM78:hover {
  border-color: #f42713;
}

.Input_danger__KRM78::-webkit-input-placeholder {
  color: #f42713;
}

.Input_danger__KRM78:-ms-input-placeholder {
  color: #f42713;
}

.Input_danger__KRM78::-ms-input-placeholder {
  color: #f42713;
}

.Input_danger__KRM78::placeholder {
  color: #f42713;
}

.IconButton_btn__3N2u3 {
  line-height: 1.499;
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  padding: 4px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.65);
  background-color: transparent;
  border: none;
  border-color: rgb(217, 217, 217);
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: 32px;
  height: 32px;
}

.IconButton_btn__3N2u3:active,
.IconButton_btn__3N2u3:hover,
.IconButton_btn__3N2u3:focus {
  text-decoration: none;
  outline: none;
  border: 2px solid var(--primary-color);
  background-color: #f3f3f3;
}

.IconButton_btn__3N2u3:hover {
  color: var(--primary-light-color);
}

.IconButton_btn__3N2u3:active {
  color: var(--primary-dark-color);
}

.IconButton_btn__3N2u3:disabled,
.IconButton_btn__3N2u3[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #eee;
}

.IconButton_btn__3N2u3[disabled]:hover {
  border: none;
  color: rgba(0, 0, 0, 0.25);
  background-color: #eee;
}
.IconButton_danger__1gWHL:hover {
  border-color: transparent !important;
  color: red !important;
}

.IconButton_danger__1gWHL:hover,
.IconButton_danger__1gWHL:focus {
  color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
}

.IconButton_danger__1gWHL:active {
  color: var(--secondary-dark-color);
  border: 2px solid var(--secondary-dark-color);
}

.IconButton_ghost__3hQjv {
  color: rgba(255, 255, 255, 0.8);
  background-color: transparent;
}

.IconButton_ghost__3hQjv:hover,
.IconButton_ghost__3hQjv:focus {
  color: rgba(255, 255, 255, 0.85);
  background-color: transparent;
}

.IconButton_ghost__3hQjv:active {
  color: rgba(255, 255, 255, 1);
  background-color: transparent;
}

.IconButton_ghost__3hQjv:disabled,
.IconButton_ghost__3hQjv[disabled] {
  border: none;
  color: rgba(255, 255, 255, 0.55);
}

.Text_secondary__2My5S {
  color: rgba(0, 0, 0, 0.4);
}

.Text_danger__1MxfX {
  color: var(--secondary-color);
}

.DataNotification_text__1g0fB {
  margin-right: 0.5rem;
  white-space: nowrap;
}

.DataNotification_AlertIcon__3Vace {
  margin-right: 0.5rem;
  position: relative;
  top: 3px;
}

/*  spinkit cube http://tobiasahlin.com/spinkit/ */
.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
  z-index: 99;
}
.sk-cube-grid {
  width: 60px;
  height: 60px;
  margin: 10px auto;
}
.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  /* background-color: #333; */
  background-color: magenta;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    transform: scale3D(1, 1, 1);
    background-color: magenta;
  }
  35% {
    transform: scale3D(0, 0, 1);
    background-color: rgb(0, 183, 255);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    transform: scale3D(1, 1, 1);
    background-color: rgb(0, 183, 255);
    box-shadow: rgba(247, 7, 247, 0.5) 2px 2px 20px 2px;
  }
  35% {
    transform: scale3D(0, 0, 1);
    background-color: magenta;
    box-shadow: rgba(64, 169, 255, 0.5) 2px 2px 20px 2px;
  }
}

.menu-active {
  text-decoration: none !important;
  border-bottom: 3px solid white !important;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  font-weight: 700 !important;
  /* padding: 0px !important; */
  padding-bottom: 7px !important;
  height: 40px !important;
  margin-left: 15px;
}

.sub-menu-active {
  text-decoration: none !important;
  font-weight: 700 !important;
  padding: 0px !important;
  margin-left: 15px;
}
.menu {
  font: 14px 'Open Sans';
  font-weight: 500;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 40px !important;
  padding-bottom: 8px !important;
  margin-left: 15px;
  color: #ffffff !important;
}

.img-fluid {
  width: 195px !important;
}

/* Welcome Dialog  */
.welcome-title-popup {
  padding: 20px 20px;
}

.welcome-title-popup ul {
  margin: 0px;
  padding: 0px;
}

.welcome-title-popup ul li {
  list-style: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  padding: 15px 0px;
  color: #333333;
  border-bottom: 1px solid #c4c4c4;
}

.welcome-title {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  color: #333333;
  text-decoration: none !important;
  /* border-bottom: 4px solid #ee4036 !important; */
}

.welcome-typo {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.51px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
  padding: 15px 0px;
}
.welcome-title-popup a {
  color: #333333;
}
.welcome-title-popup a:hover {
  outline: none;
  text-decoration: none;
  color: #333333;
}
.welcome-title-popup a:visited {
  outline: none;
  text-decoration: none;
  border: none;
}

.welcome-progress {
  height: 9px;
  background-color: #eb4734 !important;
}
.progress-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */
  text-align: center;
  color: #000000;
  margin-bottom: 5px;
}
.h-20 {
  height: 20px;
}
video {
  /* override other styles to make responsive */
  width: 100% !important;
  height: auto !important;
  /* object-fit: cover; */
}
.account-ready {
  text-align: right;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #424242;
  padding-top: 5px;
}
.take-me-button {
  width: auto !important;
  height: 40px !important;
  background-color: #ee4036;
  border-radius: 0% !important;
  padding: 0px 20px 0px 20px !important;
  text-align: left;
}

/* Direct integration CSS */

.direct-heading {
  font-size: 24px;
  height: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  text-align: left;
  border-bottom: 1px none #f8f6f6;
  position: relative;
  z-index: 1;
  color: black;
}
.direct-heading :before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 50%;
  border-bottom: 1px solid #c4c4c4;
}

.table-border {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  margin-top: 2%;
  margin-bottom: 2%;
  padding: 30px;
}

.table-ui td:not(:first-child) {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  -ms-flex-align: center;
      align-items: center;
  color: #424242;
}

.table-ui tbody > tr:last-child > td {
  border-bottom: 0 !important;
}

.table-header th {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 18px;
  line-height: 25px;
  -ms-flex-align: center;
      align-items: center;
  color: #000000;
}
.border-top-none {
  border-top: none !important;
  border-bottom: 1px solid #c4c4c4 !important;
}
.border-bottom-none {
  border-bottom: none !important;
}
.table tbody tr td {
  border-bottom: 1px solid #c4c4c4 !important;
}

.loading-div {
  top: 50%;
  left: 45%;
  position: fixed;
  z-index: 10000;
}

/* Editable Table CSS  */
.editable-table tbody tr td:nth-child(1) {
  width: 40%;
}
.editable-table tbody tr td:nth-child(2) {
  width: 30%;
}
.editable-table tbody tr td:nth-child(3) {
  width: 30%;
}
table tr td a:hover {
  border: none;
  outline: none;
}
.edit-cell {
  padding: 0px;
}
.edit-cell input {
  border: none;
  padding: 4px 8px;
  width: 100%;
  outline: 1px solid #40a9ff;
  margin-bottom: 5px;
  border-radius: 3px;
}

.edit-cell input:focus {
  outline: 1px solid #40a9ff;
}

.edit-cell div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  -ms-flex-align: center;
      align-items: center;
}
.edit-cell {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  -ms-flex-align: center;
      align-items: center;
}
.edit-button {
  width: 30px;
  height: 30px;
  padding: 0px;
  color: #222;
  margin: 0px;
  margin-left: 5px;
  border-radius: 4px;
  background: #f5f6f8;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}
.edit-img {
  height: 15px;
  width: 30px;
}

.edit-cell div button:hover {
  background: #eaeaeb;
}

.edit-cell div button:focus {
  outline: none;
}

.edit-cell div button img {
  width: 10px;
}

.hover {
  cursor: pointer;
}
.error-msg {
  font-size: 12px;
  color: red;
}
button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
  color: #ee4036;
}
/* End of Direct integration CSS */

/* Connectiom message */
.msg-anchor {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  -ms-flex-align: center;
      align-items: center;
  color: #4578fb;
  margin-left: 40px;
}

.list li {
  float: left;
  list-style-position: inside;
  list-style-type: disc;
  margin-right: 1em;
  font-weight: bold;
}

.list {
  padding-left: 1em;
}

.list:after {
  /* Clearfix hack */
  content: '';
  display: block;
  clear: both;
}

/* unvisited link */
a:link {
  text-decoration: none !important;
}

/* visited link */
a:visited {
  text-decoration: none !important;
}

a:active,
a:hover,
a:focus {
  text-decoration: none !important;
  outline: none !important;
}

.limited-first-line {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  -ms-flex-align: center;
      align-items: center;
  color: #424242;
}
.limited-second-line {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #424242;
  margin-top: 20px;
}

.expanded-first-line {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  -ms-flex-align: center;
      align-items: center;
  color: #424242;
}
/* End of Connectiom message */

.mt-40 {
  margin-top: 40px;
}

/* Home page style */

.welcome-msg {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: top;
      align-items: top;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  color: #424242;
  -webkit-text-stroke-width: thin;
}
.card-style {
  background: #ffffff;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  cursor: pointer;
  min-height: 400px;
  max-height: 400px;
  overflow-y: auto;
  /* width: 300px; */
  padding: 20px;
  padding-bottom: 5px !important;
  /* max-width: 345px !important; */
}

.card-style:hover .card-title {
  color: #bf1625 !important;
}
.card-style:hover {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
}
.card-title {
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 20px !important;
  line-height: 28px !important;
  color: #424242 !important;
  letter-spacing: 1px;
  -webkit-text-stroke-width: thin;
}

.card-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 23px !important;
  color: #424242 !important;
  /* text-align: justify; */
}

.card-image {
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  margin-top: 20px;
  height: 100px;
  width: 155px;
}

.row-heading {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 28px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  color: #424242;
}

.grayscale {
  /* filter: grayscale(100%) !important; */
}
.disable-img {
  -webkit-filter: grayscale(100%) !important;
          filter: grayscale(100%) !important;
}

.grayscale:hover {
  cursor: default;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
}

.coming-soon {
  color: #bf1625;
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 24px !important;
  line-height: 28px !important;
  /* margin-bottom: 5px; */
}

.coming-soon-bottom {
  color: #bf1625;
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 24px !important;
  line-height: 28px !important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 35px;
}

.info-msg {
  font-weight: bold !important;
  font-size: 15.5px !important;
  color: #bf1625 !important;
  /* text-align: center !important; */
  margin-top: 130px !important;
  line-height: 20px;
  padding-top: 70px !important;
  margin-left: 20px !important;
}
.uvs {
  position: relative !important;
}

.card-img-bottom {
  width: 155px !important;
}
.card-img-overlay {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1000;
}
.uvs:hover .card-img-overlay {
  opacity: 0.8;
}
.card-uvs {
  position: absolute;
  top: 0%;
  left: 20%;
  -ms-transform: translate(-10%, -10%);
  transform: translate(-10%, -10%);
}

.uvs:hover .grayscale-hover {
  -webkit-filter: grayscale(90%) !important;
          filter: grayscale(90%) !important;
}
.uvs:hover .grayscale-hover .card-text {
  visibility: hidden;
}

/* End of Home page */

/* Start Main Menu */

.navbar-toggler:focus {
  outline: 0px auto !important;
}

@media only screen and (max-width: 480px) {
  .img-fluid {
    width: 165px !important;
  }

  .navbar-brand div {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .bg-md-white a button {
    color: #000000 !important;
  }
  .navbar-dark .navbar-toggler {
    text-align: right;
    float: right;
  }
  .bg-sm-white {
    background-color: white;
  }
  .text-sm-dark {
    color: #000000;
  }

  .menu-active {
    text-decoration: none !important;
    border-bottom: 3px solid rgb(0, 0, 0) !important;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    font-weight: 700 !important;
    /* padding: 0px !important; */
    padding-bottom: 7px !important;
    height: 40px !important;
    margin-left: 15px;
    background-color: white !important;
    color: black !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
  }
  .navbar {
    display: block !important;
  }
  .navbar-dark .navbar-toggler {
    position: absolute;
    right: 12px !important;
  }
  .userprofile-icon {
    position: absolute;
    right: 44px !important;
    top: 13px;
  }
  a button:hover {
    border: 2px solid white !important;
    color: #1d1d1d !important;
  }
}
@media (min-width: 481px) and (max-width: 990px) {
  .navbar-dark .navbar-toggler {
    text-align: right;
    float: right;
  }

  .bg-md-white {
    background-color: white;
  }
  .tab-nav,
  .tab-menu {
    width: 100% !important;
    margin: 0 !important;
  }
  .bg-md-white a button {
    color: #000000 !important;
  }
  .menu-active {
    text-decoration: none !important;
    border-bottom: 3px solid rgb(0, 0, 0) !important;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    font-weight: 700 !important;
    /* padding: 0px !important; */
    padding-bottom: 7px !important;
    height: 40px !important;
    margin-left: 15px;
    background-color: white !important;
    color: black !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
  }
  img.hover.userprofile-icon {
    position: absolute;
    right: 44px;
    top: 13px;
  }
  a button:hover {
    border: 2px solid white !important;
    color: #1d1d1d !important;
  }
}
.mdi-icon {
  margin-bottom: 0px !important;
}
@supports (-moz-appearance: none) {
  .mdi-icon {
    margin-bottom: 0px !important;
  }
  .singleSelect .searchBox {
    pointer-events: none;
  }
}
@media (min-width: 992px) {
  .navbar-brand div {
    margin-right: 0px !important;
  }
  .userprofile-icon {
    position: absolute;
    right: 0px !important;
    top: 13px;
  }
}
.navbar-dark .navbar-toggler {
  position: absolute;
  right: 15px;
}
/* #responsive-navbar-nav .navbar-nav a div > button:hover,
#responsive-navbar-nav .navbar-nav a div > button:focus,
#responsive-navbar-nav .navbar-nav a > button:hover,
#responsive-navbar-nav .navbar-nav a > button:focus {
  border: 0px solid #1b1b1b !important;
  transition: ease 0s !important;
} */

.userprofile-icon {
  position: absolute;
  right: 30px;
  top: 13px;
}
.userprofile-icon button:hover {
  border: none;
}
.userprofile-icon button {
  width: 40px;
}

@media (min-width: 320px) and (max-width: 359px) {
  .userprofile-icon {
    top: 6px;
  }
  .card-style {
    max-width: 270px !important;
    min-width: 270px !important;
  }
  .col {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
  }
  .welcome-msg {
    font-size: 15px;
  }
  .row-heading {
    font-size: 20px;
  }
  .card-title {
    font-size: 15px !important;
  }
  .card-text {
    font-size: 14px !important;
  }
}
@media (min-width: 360px) and (max-width: 640px) {
  .card-style {
    max-width: 320px !important;
    min-width: 320px !important;
  }
  .col {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
  }
  .welcome-msg {
    font-size: 18px;
  }
  .row-heading {
    font-size: 22px;
  }
  .card-title {
    font-size: 18px !important;
  }
  .card-text {
    font-size: 15px !important;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .card-style {
    max-width: 315px !important;
    min-width: 315px !important;
  }
  .col {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
  }
  .welcome-msg {
    font-size: 25px;
  }
  .row-heading {
    font-size: 25px;
  }
  .card-title {
    font-size: 20px !important;
  }
  .card-text {
    font-size: 15px !important;
  }
}
@media (min-width: 1008px) and (max-width: 1365px) {
  .card-style {
    max-width: 255px !important;
    min-width: 255px !important;
  }
  .col {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
  }
}

@media (min-width: 1366px) and (max-width: 2559px) {
  .card-style {
    max-width: 300px !important;
    min-width: 270px !important;
  }
  .col {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
  }
}
@media (min-width: 2560px) and (max-width: 3840px) {
  .card-style {
    max-width: 430px !important;
    min-width: 430px !important;
  }
  .col {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
  }
  .welcome-msg {
    font-size: 45px;
  }
}

/* API Style */

.copy-btn {
  background-color: #eb4734;
  border-color: #eb4734;
  width: 100px;
  /* height: 35px; */
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  border-radius: 0 !important;
  color: #ffffff;
  margin-left: 10px;
}
.copy-btn:hover {
  background-color: #dd3c30;
  border-color: #dd3c30;
  color: white;
}
.copy-btn:focus {
  outline: none;
}
.copy-msg {
  display: inline-block;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  padding: 10px;
  box-sizing: border-box;
  max-width: 350px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  position: absolute;
  z-index: 100px;
}

.close-btn {
  float: right;
  padding-left: 10px;
  /* padding: 2px 5px; */
}

.copy-tick {
  background-color: green;
  color: white;
  border-radius: 40px;
  margin-right: 5px;
}
.link-tick {
  background-color: #ffffff;
  color: #eb4734;
  border-radius: 40px;
}

.invite-team {
  border-top: 1px solid black;
}

.form-control {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

/* Start Solution pack */

.fav {
  height: 20px;
  width: 20px !important;
  margin-left: 90%;
}

.card-image-solution {
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  height: 105px;
  width: 155px;
}
/* //Footer Style */
.main-footer {
  height: 225px;
  font-family: 'Open Sans';
  color: #ffffff;
}
.footer-left-content {
  padding: 20px;
  margin-left: 10%;
}

.footer-learn-more {
  width: 315px;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 20px;
}

.footer-copy {
  float: right;
  padding: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  -ms-flex-align: center;
      align-items: center;
  text-align: right;
  margin-right: 50px;
}

.no-anchor {
  color: white !important;
}
.no-anchor:link {
  color: white !important;
}

.no-anchor:hover {
  color: white !important;
}

.no-anchor:active {
  color: white !important;
}
.download {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  color: #ff2e2e;
  font-weight: bolder;
  padding-left: 0.5rem;
}
.card-download {
  font-size: 0.7rem !important;
  margin-bottom: 13px;
}

.download-link {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  margin-left: 15px;
}

@media screen and (max-width: 1890px) and (min-width: 768px) {
  .card-control {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 4 !important;
    -webkit-box-orient: vertical !important;
  }
}
@media screen and (max-width: 768px) {
  .card-control {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 4 !important;
    -webkit-box-orient: vertical !important;
  }
}

@media only screen and (min-width: 1850px) {
  .card-control {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 4 !important;
    -webkit-box-orient: vertical !important;
  }
}

@media screen and (max-width: 1920px) and (min-width: 768px) {
  .card-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }
}
@media screen and (max-width: 768px) {
  .card-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}

@media only screen and (min-width: 1850px) {
  .card-text {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

/* //Coverage reports */
.coverage-reports-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #424242;
}
.coverage-reports-sub-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}
.div-bottom-border {
  border-bottom: 1px solid rgb(51, 49, 49);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  color: #424242;
}
.download-icon {
  float: right;
  cursor: pointer;
}
.arrow-icon {
  float: right;
  cursor: pointer;
  margin-top: 10px;
}
.coverage-reports-section-header {
  margin-bottom: 10;
  background-color: rgba(244, 244, 244, 1) !important;
  border-bottom: 1.5px solid black !important;
  color: black;
  padding: 0px !important;
  padding-bottom: 10px !important;
  margin-bottom: 20px !important;
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 29px;
  color: #424242;
}

/* Solution Detail */
.detail-bg {
  display: inline-block;
  width: 100%;
  background: #ffffff;
  margin-top: -19px;
}
.detail-img {
  /* margin-bottom: -125px; */
  width: 0%;
}
.detail-img,
.detail-heading,
.solution-detail {
  display: inline;
  /* margin: auto; */
}
.detail-img img {
  width: 74.5px;
  margin-bottom: 12px;
}
.detail-grid {
  padding-left: 100px;
}
.detail-container {
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
}
.grid ul {
  line-height: 24px;
  word-spacing: 1px;
  -webkit-column-count: 2;
          column-count: 2;
  list-style-position: outside;
}
.solution-detail {
  color: #eb4734;
  height: 39px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  /* width: 148px; */
  padding-top: 7px;
  grid-gap: -13px;
  grid-gap: -13px;
  gap: -13px;
  position: absolute;
  right: 160px;
  -webkit-text-stroke-width: 0px;
}
@-webkit-keyframes slideInFromtop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideInFromtop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
.detail-container-para {
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-iteration-count: 0;
          animation-iteration-count: 0;
  -webkit-animation-name: slideInFromtop;
          animation-name: slideInFromtop;
}
.solution-detail-button {
  font-family: 'Open Sans' !important;
  font-style: normal;
  line-height: 22px;
  color: #fff;
  background-color: #eb4734;
  border-color: #eb4734;
  border-radius: 0px !important;
  height: 39px;
  font-weight: bold;
  font-size: 15px;
  width: 120px;
  outline: none !important;
  position: absolute;
  right: 80px;
  letter-spacing: 1px;
  font-weight: lighter;
}
.detail-arrow-img {
  margin-left: 6px;
  margin-right: -22px;
  margin-bottom: 3px;

  image-rendering: crisp-edges !important;
  transition: 3s !important;
}
.solution-para-text {
  color: #424242;
  line-height: 26px;
}
.detail-heading {
  font-weight: bolder;
  display: -ms-flexbox;
  display: flex;
  left: 235px;
  top: 496px;
  color: #424242;
  /* padding-bottom: 24px; */
  -webkit-text-stroke-width: thin;
}
.grid div li {
  list-style-type: square;
  font-size: 18px;
  margin-right: 53px;
  color: #424242;
}
li::marker {
  color: #eb4734;
  font-size: 24px;
}
.grid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  grid-gap: 35px;
  gap: 35px;
}
.query-head {
  border-bottom: 5px solid #eb4734;
  width: 12rem;
  color: #424242;
  font-weight: bolder;
  font-size: 23px;
  margin: 2px;
  padding-bottom: 3px;
  -webkit-text-stroke-width: thin;
}
.analytics-head {
  border-bottom: 5px solid #eb4734;
  width: 13rem;
  color: #424242;
  font-weight: bolder;
  font-size: 23px;
  margin: 2px;
  padding-bottom: 3px;
  -webkit-text-stroke-width: thin;
}
.editor {
  color: #eb4734;
  cursor: pointer;
  font-weight: bolder;
}
.query-card {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  grid-gap: 15px;
  gap: 15px;
}
.queryImg {
  width: 44px;
}
.runButton {
  font-size: 16px;
  color: #eb4734;
  font-weight: bold;
  padding-left: 3px;
}
.query-download .play {
  width: 22px;
}
.QueryDownload a {
  color: #eb4734;
  font-size: 15px;
  font-weight: bolder;
}
.container-card-meta {
  margin-bottom: -9px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  grid-gap: 0px;
  grid-gap: 0px;
  grid-gap: 10px;
  gap: 10px;
  border-bottom: 1px solid #8d8d8d;
  width: 100%;
  padding: 0px;
  margin-top: 18px;
}
.container-card-meta:last-child {
  border: none;
}
.sample-list {
  margin-top: 19px;
  margin-bottom: -26px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  grid-gap: 0px;
  grid-gap: 0px;
  grid-gap: 10px;
  gap: 10px;
  border-top: 1px solid #8d8d8d;
  width: 100%;
  padding: 6px;
}
.show-in-line {
  width: 78%;
}
.text-size {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #424242;
}
.meta-download-page {
  width: 54px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: end;
  grid-gap: 8px;
  gap: 8px;
}

.meta-download {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: start !important;
      justify-content: flex-start !important;
  grid-gap: 8px;
  gap: 8px;
}
.card-download-meta {
  width: 17.82px;
  cursor: pointer;
  padding-left: 0px !important;
}
.meta-download p {
  color: #eb4734;
  font-size: 15px;
  font-weight: bolder;
  text-align: center;
  cursor: pointer;
  /* padding-left: 10px; */
  margin: 2px 0 0 0 !important;
  -webkit-text-stroke-width: thin;
  letter-spacing: 0.6px;
}
.container {
  width: 100%;
  margin-top: 20px;
}
.query-download {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  color: #eb4734;
  font-weight: bolder;
  padding-left: 1.5rem;
  padding-top: 15px;
}
.back-button {
  padding-top: 9px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  cursor: pointer;
}
.show-grid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
}
.meta-header-caption {
  font-size: 18px;
  color: #424242;
  padding: 5px;
}
.meta-head {
  border-bottom: 5px solid #eb4734;
  width: 14rem;
  color: #424242;
  font-weight: bolder;
  font-size: 23px;
  margin: 2px;
  padding-bottom: 3px;
  -webkit-text-stroke-width: thin;
}
.sample-img-solution img {
  width: 44px;
  margin-top: 4px;
  padding-right: 13px;
  margin-left: 26px;
  height: 30px;
}
.dive-deep {
  background-color: #404040;
  width: 100%;
}
.header-caption {
  font-size: 18px;
  color: #424242;
}
.dive-heading {
  border-bottom: 5px solid #eb4734;
  width: 9rem;
  color: #ffffff;
  font-size: 23px;
  font-weight: bolder;
  margin: 1px;
  padding-top: 16px;
  padding-bottom: 15px;
}
.dive-headerCaption {
  font-size: 16px;
  color: #ffffff;
  padding-top: 16px;
  padding-bottom: 16px;
}
.dive-download img {
  left: 60px;
  width: 29px;
  position: absolute;
  bottom: 14px;
}
.dive-download p {
  color: #eb4734;
  font-size: 15px;
  font-weight: bolder;
  cursor: pointer;
  position: absolute;
  left: 80px;
  bottom: 0px;
  padding-left: 20px;
}
.dive-card-img {
  width: 263.82px;
  height: 160px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
}
.show-in-line h5 {
  /* color: #424242; */
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
}
@media (max-width: 321px) {
  .trial-text {
    line-height: normal;
    line-height: initial;
  }
  .trial-text-np {
    line-height: normal;
    line-height: initial;
  }
  /* .detail-grid {
    padding-left: 25px;
  }
  .detail-heading {
     padding-left: 75px; 
    font-size: 22px;
  } */
  .detail-container-para {
    margin-top: 62px;
  }
  .detail-grid {
    padding-left: 0px !important;
  }
  .detail-heading {
    /* margin-top: 5px; */
    padding-left: 75px;
    font-size: 18px;
  }
  .solution-detail {
    right: 0px !important;
    width: 30%;
  }
  .solution-detail-button {
    font-size: 16px;
    width: 75px;
    right: 0px;
  }
  .grid ul {
    line-height: 21pt !important;
    word-spacing: 1px;
    -webkit-column-count: 1 !important;
            column-count: 1 !important;
  }
  .card-dive-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .detail-img {
    /* margin-bottom: -125px; */
    width: 0% !important;
  }
}
@media only screen and (min-device-width: 322px) and (max-width: 376px) {
  .detail-container-para {
    margin-top: 62px;
  }
  .detail-heading {
    /* margin-top: 5px; */
    padding-left: 73px !important;
    font-size: 18px;
  }

  .trial-text {
    line-height: normal;
    line-height: initial;
  }
  .trial-text-np {
    line-height: normal;
    line-height: initial;
  }
  .detail-grid {
    padding-left: 0px !important;
  }
  .solution-detail {
    padding-top: 0px;
    right: 0px !important;
    width: 28%;
  }
  .solution-detail-button {
    font-size: 16px;
    width: 75px;
    right: 11px;
  }
  .grid ul {
    line-height: 21pt !important;
    word-spacing: 1px;
    -webkit-column-count: 1 !important;
            column-count: 1 !important;
  }
  .card-dive-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
@media screen and (min-device-width: 376px) and (max-device-width: 500px) {
  .detail-grid {
    padding-left: 40px;
    /* margin-top: 68px; */
  }
  .react-multiple-carousel__arrow--left {
    left: calc(0% + -7px);
  }
  .grid ul {
    line-height: 21pt !important;
    word-spacing: 1px;
    -webkit-column-count: 1 !important;
            column-count: 1 !important;
  }
  .detail-img {
    /* margin-bottom: -125px; */
    width: 10%;
  }
  .detail-heading {
    /* margin-top: 5px; */
    /* padding-left: 80px; */
    font-size: 22px;
  }
  .solution-detail-button {
    width: 90px;
    position: absolute;
    right: 15px !important;
  }
  .solution-detail {
    padding-top: 4px;
    width: 90px;
    position: absolute;
    right: 15px !important;
  }
  .text-size {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: #424242;
    padding-right: 10px;
  }
  .query-container {
    width: 100%;
    padding-right: 15px;
    margin-left: auto;
  }
  .card-dive-text {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
  }
  .trial-main {
    display: inline-table;
  }
  .trial-main-np {
    display: inline-table;
  }
  .trial-text {
    line-height: normal;
    line-height: initial;
  }
  .trial-text-np {
    line-height: normal;
    line-height: initial;
  }
  /* .card-query-style {
    padding: 0px !important;
  } */
}
@media screen and (min-width: 500px) and (max-width: 770px) {
  .detail-grid {
    padding-left: 0px;
    /* margin-top: 68px; */
  }
  .solution-type-home {
    height: 22px;
    background: #ebc259;
    width: 112px;
    margin-bottom: 7px;
    margin-left: 80px;
  }
  .dataset-type-home {
    height: 22px;
    background: #c7d6e3;
    width: 76px;
    margin-bottom: 7px;
    margin-left: 80px;
  }
  /* .card-query-style {
    padding: 0px !important;
  } */
  .detail-heading {
    /* margin-top: 5px; */
    padding-left: 80px;
    font-size: 30px;
  }
  .grid ul {
    -webkit-column-count: 2 !important;
            column-count: 2 !important;
  }

  .detail-img img {
    width: 74.5px;
    /* margin-bottom: -75px; */
  }
  .card-dive-text {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
  }
  .solution-detail-button {
    position: absolute;
    right: 30px !important;
  }
  .solution-detail {
    padding-top: 5px;
    position: absolute;
    right: 70px !important;
  }
  .react-multiple-carousel__arrow--left {
    left: -1px !important;
  }
  .query-container {
    width: 100%;
    padding-right: 15px;
    margin-left: auto;
  }
  .trial-text {
    line-height: normal;
    line-height: initial;
  }
  .trial-text-np {
    line-height: normal;
    line-height: initial;
  }
  .solution-para-text {
    padding-left: 80px;
  }
}
@media screen and (min-device-width: 771px) and (max-device-width: 970px) {
  .react-multi-carousel-item,
  .react-multi-carousel-item--active {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    position: relative !important;
    max-width: 315 !important;
  }
  .card-dive-text {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
  }
  .solution-detail {
    position: absolute;
    right: 70px !important;
  }
  .solution-detail-button {
    position: absolute;
    right: 18px !important;
  }
}

@media screen and (min-device-width: 970px) and (max-device-width: 1100px) {
  .react-multi-carousel-item,
  .react-multi-carousel-item--active {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    position: relative !important;
    max-width: 330px !important;
  }
  .card-dive-text {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
  }
  .solution-detail {
    position: absolute;
    right: 85px !important;
  }
  .solution-detail-button {
    right: 45px;
  }
}
@media screen and (min-device-width: 1100px) and (max-device-width: 1300px) {
  .query-container,
  .detail-containers,
  .meta-container,
  .sample-container,
  .dive-container {
    max-width: 76% !important;
    padding-left: 50px !important;
  }
  .detail-grid {
    padding-left: 100px;
    /* margin-top: 68px; */
  }
  .grid ul {
    line-height: 21pt !important;
    word-spacing: 1px;
    -webkit-column-count: 2 !important;
            column-count: 2 !important;
  }
  .solution-detail {
    position: absolute;
    right: 100px !important;
  }
  .solution-detail-button {
    right: 100px !important;
  }
  .react-multiple-carousel__arrow--left {
    left: calc(2% + 1px) !important;
  }
  .show-in-line {
    width: 100%;
  }
  .container-card-meta {
    width: 100%;
    margin-bottom: 0px;
  }
  .sample-list {
    width: 100%;
    margin-bottom: 0px;
  }
  .dive-card-img {
    width: 263.82px;
    height: 160px;
  }

  .card-control {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 4 !important;
    -webkit-box-orient: vertical !important;
  }
  .react-multi-carousel-item,
  .react-multi-carousel-item--active {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    position: relative !important;
    max-width: 325px !important;
  }
  .download {
    margin-top: 15px;
  }
  .detail-heading {
    /* margin-top: -115px !important; */
    /* margin-left: 89px !important; */
  }
  .card-dive-text {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
  }
}

@media screen and (min-device-width: 1300px) and (max-device-width: 1920px) {
  .container-card-meta {
    width: 100%;
  }
  .sample-list {
    width: 100%;
  }
  .dive-card-img {
    width: 263.82px;
    height: 160px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .react-multi-carousel-item,
  .react-multi-carousel-item--active {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    position: relative !important;
    width: 315px !important;
  }
  .card-download-meta,
  .card-download-para {
    /* padding-left: 10px !important; */
    margin: 0 !important;
  }
  .card-dive-text {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
  }
  .solution-detail-button {
    position: absolute;
    right: 160px;
  }
}
@media screen and (min-device-width: 1921px) and (max-device-width: 2550px) {
  .card-download-meta,
  .card-download-para {
    /* padding-left: 165px; */
    margin: 0 !important;
  }
  .react-multi-carousel-item,
  .react-multi-carousel-item--active {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    position: relative !important;
    width: 325px !important;
  }
  .card-dive-text {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
  }
  .solution-detail-button {
    position: absolute;
    right: 260px;
  }
}
@media screen and (min-device-width: 1300px) and (max-device-width: 2550px) {
  .query-container,
  .detail-containers,
  .meta-container,
  .sample-container,
  .dive-container {
    max-width: 76% !important;
    padding-left: 60px !important;
  }

  .dive-container {
    margin-top: 61px !important;
  }
  /* .dive-download p {
    margin-top: 0px;
  } */
  .dive-card-img {
    width: 263.82px;
    height: 160px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  /* .card-query-style {
    min-width: 270px !important;
    max-width: 325px !important;
  } */
}

@media screen and (min-device-width: 2550px) and (max-device-width: 5500px) {
  .query-container,
  .detail-containers,
  .dive-container,
  .meta-container,
  .sample-container,
  .dive-container {
    max-width: 76% !important;
    padding-left: 20px !important;
  }
  .card-control {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 4 !important;
    -webkit-box-orient: vertical !important;
  }
  /* .dive-download p {
    margin-top: -6px;
    margin-left: 30px;
  }
  .dive-download img {
    margin-bottom: 0px;
    margin-left: 38px;
  } */
  .sample-list {
    margin-bottom: -26px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    grid-gap: 0px;
    grid-gap: 0px;
    grid-gap: 0px;
    gap: 0px;
    border-top: 1px solid #8d8d8d;
    max-width: 100% !important;
  }
  .solution-detail {
    right: 390px !important;
  }
  .solution-detail-button {
    right: 460px !important;
  }
  .card-download-meta,
  .card-download-para {
    /* padding-left: 240px; */
    margin: 0 !important;
  }
  .meta-download {
    padding-left: 10px;
  }
  .container-card-meta {
    width: 100%;
  }
  .sample-list {
    width: 100%;
  }
  .detail-heading {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }
  .dive-card-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 15px !important;
    width: 263.82px;
    height: 160px;
  }
  .react-multi-carousel-item,
  .react-multi-carousel-item--active {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    position: relative !important;
    width: 325px !important;
  }
  /* .card-query-style {
    max-width: 380px !important;
  } */
}
@media screen and (min-width: 2550px) {
  /* .solution-detail-button {
    right: 500px !important;
  } */
  .card-dive-text {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
  }
  .solution-detail {
    right: 500px !important;
  }
}
.react-multiple-carousel__arrow--left::before {
  margin-right: 125px !important;
}
.react-multiple-carousel__arrow--right::before {
  padding-left: 160px !important;
  content: '\e825' !important;
}
.carousel-dive-color .react-multiple-carousel__arrow::before {
  font-size: 32px !important;
  color: #fff !important;
  display: block !important;
  font-family: revicons;
}
.react-multiple-carousel__arrow::before {
  font-size: 32px !important;
  color: black !important;
  display: block !important;
  font-family: revicons;
}
.react-multiple-carousel__arrow {
  background-color: transparent !important;
  outline: none !important;
}
[dir='rtl'] .carousel-container {
  direction: ltr;
}
.react-multiple-carousel__arrow:hover {
  background-color: transparent !important;
  outline: none !important;
}
.react-multi-carousel-list {
  position: static !important;
}
.trial-main {
  background-color: #ffffff;
  text-align: center;
  padding-top: 82px;
  padding-bottom: 82px;
  width: 100%;
  height: 206px;
  line-height: 1pt;
}
.trial-heading {
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  color: #424242;
}
.trial-text {
  font-size: 18px;
  color: #424242;
}
.trial-link {
  font-size: 18px;
  font-weight: bold;
  color: #424242;
  -webkit-text-decoration-line: underline !important;
          text-decoration-line: underline !important;
  cursor: pointer;
}
.trail-main-np {
  background-color: #eb4734;
  text-align: center;
  padding-top: 82px;
  padding-bottom: 82px;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  line-height: 1pt;
}
.trial-heading-np {
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  color: #fff;
}
.trial-text-np {
  font-size: 18px;
  color: #fff;
  padding-top: 10px;
  word-spacing: 2px;
  line-height: normal;
  line-height: initial;
}
.trial-anchor {
  text-decoration: none;
  color: #424242;
}
.trial-anchor:hover {
  text-decoration: none;
  color: #424242;
}
.card-query-style {
  background: #ffffff;
  margin-left: 15px !important;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 12%);
  border-radius: 2px;
  cursor: pointer;
  min-width: 270px;
  max-width: 310px !important;
  min-height: 380px;
  max-height: 380px;
  padding-top: 23px;
  padding-left: 40px;
  padding-right: 40px;
}

.react-multi-carousel-item,
.react-multi-carousel-item--active {
  -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important;
  position: relative !important;
  width: 330px !important;
}

/* Solution Purchase dialog */

.purchaseDialog {
  font-weight: bolder;
  font-size: 30px;
  color: #424242;
  width: 75vw !important;
  margin: 10vh auto !important;
}
.p-6 {
  padding: 6%;
  -webkit-text-stroke-width: thin;
  margin-left: 35px;
}
.btn-close {
  float: right;
}

.btn-close:active,
.btn-close:hover,
.btn-close:focus {
  border: none !important;
  text-decoration: none;
  background-color: white !important;
  color: #000000;
}

.try-button {
  width: 65.11px;
  /* height: 52.03px; */
  font-size: 16px;
  font-weight: bold;
  border-radius: 0px;
  /* font-family: 'open-sans'; */
  font-style: normal;
  -ms-flex-align: center;
      align-items: center;
  text-align: center;
  background-color: #eb4734;
  cursor: pointer;
  outline: none !important;
  margin: 20px;
}
.card-catalog-text {
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 4 !important;
  -webkit-box-orient: vertical !important;
}
.card-catalog-style {
  background: #ffffff;
  margin-left: 15px !important;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 12%);
  border-radius: 2px;
  min-width: 270px;
  max-width: 310px !important;
  min-height: 380px;
  max-height: 380px;
  padding: 23px 20px 5px 20px;
}
.search-catalog {
  margin-top: 20px;
  margin-bottom: 10px;
  min-width: 274px;
  max-width: 314px;
  min-height: 38px;
  max-height: 38px;
  outline: none;
  border: 1px solid #8d8d8d;
  padding-left: 12px;
}
.no-search {
  display: block;
  margin: 0px auto;
  padding-top: 32px;
}
.search-img {
  width: 20.49px;
  position: relative;
  margin-left: -30px;
  /* margin-top: 10px; */
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
.container-solutionpack {
  margin: 0px !important;
  padding: 35px 35px 70px 35px !important;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  max-width: -webkit-fill-available !important;
  min-height: 464px;
}
.heading-solution {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 50px;
  color: #424242;
  -webkit-text-stroke-width: thin;
}
.solutionPack-column {
  padding-top: 15.75px;
  padding-bottom: 15.75px;
  border-bottom: 1px solid #666666;
}
.solutionPack-row {
  display: -ms-flexbox;
  display: flex;
  grid-gap: 30px;
  gap: 30px;
  /* align-items: center; */
}
.solutionPack-row:hover {
  cursor: pointer;
}
.solutionPack-row-try {
  grid-gap: 35.58px;
  gap: 35.58px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
}
.solutionPack-row:hover {
  cursor: pointer;
}
.solutionPack-row-try {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  width: 100%;
}
.rows {
  padding-bottom: 30px;
  border-bottom: 1px solid #666666;
  margin-bottom: 10px;
}
.rows:last-child {
  border: none;
}
.button-tryClick {
  cursor: pointer;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  text-align: center;
  color: #eb4734;
}
.click-solution {
  cursor: pointer;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #eb4734;
}
.solution-heading {
  margin-top: 30px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 25px;
  color: #424242;
  -webkit-text-stroke-width: thin;
}
.solution-button {
  font-weight: bold !important;
  border-style: none !important;
  width: auto;
  margin-top: 30px;
  border-radius: 0px !important;
  background: #eb4734 !important;
  -webkit-text-stroke-width: initial !important;
}
.row-Specification {
  grid-gap: 8px !important;
  gap: 8px !important;
  -ms-flex-pack: justify !important;
      justify-content: space-between !important;
  -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
@media only screen and (max-width: 1265px) {
  .row-Specification {
    grid-gap: 18px !important;
    gap: 18px !important;
  }
  .container-solutionpack {
    padding: 35px 54px 70px 54px !important;
  }
  .interest-licence-try {
    width: 60%;
  }
}
@media only screen and (min-width: 1920px) {
  .row-Specification {
    grid-gap: 80px !important;
    gap: 80px !important;
  }
  .rows {
    padding-bottom: 23px;
  }
}
@media only screen and (min-width: 2773px) {
  .rows {
    padding-top: 25px !important;
  }
  .col-subs {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

@media only screen and (min-width: 1440px) {
  .rows {
    padding-bottom: 25px !important;
  }
  .col-subs {
    padding-right: 6px !important;
    padding-left: 6px !important;
  }
  .col-nO-sub {
    max-width: 65% !important;
  }
  .solution-more {
    grid-column-gap: 80px !important;
    -webkit-column-gap: 80px !important;
            column-gap: 80px !important;
  }
}

.propertyReport {
  padding: 25px;
  margin-top: 10px;
  margin-bottom: 10px;
  background: #ffffff !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15) !important;
  width: 100%;
}
.api-row {
  grid-gap: 90px;
  gap: 90px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.property-reports {
  -ms-flex-wrap: 'nowrap';
      flex-wrap: 'nowrap';
  grid-gap: 30px;
  gap: 30px;
}
.upgrade-data {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 50px;
  color: #424242;
  -webkit-text-stroke-width: thin;
}
.interest-licence {
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 28px !important;
  color: #424242 !important;
}
.solution-center {
  margin: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.interest-licence-try {
  width: 70%;
}
.licence {
  margin: 20px 0px 90px 0px !important;
}
.solutionPackPropertyImg {
  min-width: 242px !important;
  max-width: 474px !important;
  -ms-transform: translateY(23%);
      transform: translateY(23%);
}
.scrollable {
  overflow: auto;
  height: 250px;
}
.api-properties {
  font-size: 32px !important;
  line-height: 50px !important;
  color: #424242 !important;
}
.space-abv {
  padding-top: 49px;
}
.button-expand {
  margin: 0;
  width: 150px;
  margin-top: -12.33px;
}
.solution-more {
  -webkit-column-count: 2;
          column-count: 2;
}
.sql-editor {
  width: 35% !important;
}
.learn-more-help {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: end;
      align-items: end;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.button-copy-image {
  width: 150px !important;
}
.upper-api-button {
  padding-bottom: '54px';
  padding-top: '17px';
}
@media only screen and (max-width: 800px) {
  .solution-more {
    -webkit-column-count: 1;
            column-count: 1;
  }
  .sql-editor {
    width: 100% !important;
  }
  .upper-api-button {
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 600px) {
  .button-copy-image {
    position: absolute !important;
    margin-top: 170px !important;
  }
  .upper-api-button {
    padding-top: 150px !important;
    padding-bottom: 50px !important;
  }
  .button-expand {
    width: 190px !important;
  }
  .row-Specification {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    width: 90% !important;
    display: block !important;
  }
  .property-reports {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .container-solutionpack {
    padding: 15px !important;
  }
  .solutionPackPropertyImg {
    -ms-transform: translateY(40%);
        transform: translateY(40%);
  }
}
@media only screen and (min-width: 1200px) {
  .property-reports {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .propertyReport {
    width: 50%;
  }
}
@media only screen and (max-width: 1220px) {
  .api-row {
    grid-gap: 20px;
    gap: 20px;
  }
}
@media only screen and (max-width: 1024px) {
  .solutionPack-row-try {
    grid-gap: 20px;
    gap: 20px;
  }
  .container-solutionpack {
    padding: 35px !important;
  }
}
.multiSelectContainer {
  min-width: 262px !important;
  max-width: 97% !important;
}
.select-multi {
  margin: 20px !important;
  position: relative !important;
  min-height: 38px;
  /* max-height: 38px; */
  max-height: -webkit-fit-content !important;
  max-height: -moz-fit-content !important;
  max-height: fit-content !important;
  background: #ffffff !important;
  /* min-width: 290px !important; */
  /* max-width: 70% !important; */
  border: 1px solid #8d8d8d !important;
}

.select-multi .searchBox {
  margin-top: -3px;
}
.multiSelectContainer ul {
  padding-bottom: 10px !important;
  overflow: hidden;
  min-height: 35px !important;
  max-height: -webkit-fit-content !important;
  max-height: -moz-fit-content !important;
  max-height: fit-content !important;
}
.searchWrapper {
  border: none !important;
  max-width: 100%;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
  position: relative !important;
  padding: 0 !important;
  padding-left: 10px !important;
  padding-top: 5px !important;
}

.highlightOption {
  background: #ffffff !important;
  color: #424242 !important;
}
.search-select {
  display: -ms-flexbox;
  display: flex;
  /* flex-wrap: wrap; */
}
.optionContainer .checkbox {
  accent-color: #eb4734;
}
.searchBox {
  font-family: inherit !important;
  font-size: 18px !important;
  line-height: inherit !important;
  /* min-width: 50% !important; */
  max-width: -webkit-max-content !important;
  max-width: -moz-max-content !important;
  max-width: max-content !important;
}

/* .icon_down_dir {
  top: 0 !important;
} */
.option:hover {
  background-color: transparent !important;
  color: #424242 !important;
}
.optionContainer {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.multiSelectContainer li {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  height: 25px;
}
.icon_down_dir:before {
  content: '' !important;
  display: none !important;
}
.icon_down_dir {
  position: absolute !important;
  right: 8px !important;
  top: 5% !important;
  -ms-transform: none !important;
      transform: none !important;
}
/* @media screen and (max-width: 600px) { */
/* .search-select {
    display: block;
  } */
/* .select-multi {
    position: relative !important;
  }
  .select-multi {
    margin: 0px !important;
  }
} */
@media screen and (max-width: 1065px) {
  .search-select {
    display: block;
  }
  /* .select-multi {
    position: relative !important;
  }
  .select-multi {
    margin: 0px !important;
  } */
  .multiSelectContainer {
    margin-left: 0 !important;
  }
  .search-catalog {
    width: 97%;
    max-width: inherit;
    min-width: inherit;
  }
  .data-Element-select {
    padding-left: 0px !important;
  }
  .industries_select {
    margin-top: -10px;
  }
}

.purchaseDialogMetaData {
  width: 27vw;
  margin-top: 10%;
}
@media only screen and (max-width: 650px) {
  .purchaseDialogMetaData {
    width: 80%;
  }
  .show-in-line {
    width: 47% !important;
  }
}
@media screen and (min-device-width: 651px) and (max-device-width: 1023px) {
  .show-in-line {
    width: 60% !important;
  }
}
@media screen and (min-device-width: 1024px) and (max-device-width: 1300px) {
  .show-in-line {
    width: 75% !important;
  }
}
.meta-overlay {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #424242;
}

.meta-button {
  margin-top: -28px;
  margin-right: -25px;
}

.solution-pack-img {
  width: 50px !important;
  height: 50px !important;
}
.show-oppo {
  -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
}
.detail-bgs {
  display: inline-block;
  width: 98%;
  background: #ffffff;
  padding: 24px 37px;
  margin-top: 17px;
  margin-bottom: 36px;
  margin-left: 12px;
  border-radius: 0.25rem;
  color: #424242;
}

.data_items_list {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 10px;
  color: #eb4734;
  /* flex: 233px; */
  /* flex-grow: 1; */
  width: 33%;
}
.data_link_cursor {
  cursor: pointer;
}

.detail-containers_data {
  display: -ms-flexbox;
  display: flex;
  padding-top: 15px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  /* column-gap: 50px; */
}

.lists_editor {
  display: -ms-flexbox;
  display: flex;
  grid-gap: 30px;
  gap: 30px;
}

.lists_editor li::marker {
  color: #424242;
  font-size: small;
}

@media only screen and (max-width: 1024px) {
  .checkbox-main {
    padding-left: 0px !important;
  }
  .detail-containers_data {
    -ms-flex-direction: column;
        flex-direction: column;
  }
  .data_items_list {
    width: 100%;
  }
  .data-Element-select div:first-child {
    margin-left: 0px !important;
  }
}

.select-multis {
  padding-left: 20px;
}
/* .class div:first-child */
.data-Element-select .select-multi {
  margin-left: 23px !important;
}
.data-Element-select .searchBox {
  padding-left: 15px;
}
.isPurchasedCatalog {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: end;
      align-content: end;
  -ms-flex-align: baseline;
      align-items: baseline;
  -ms-flex-pack: justify;
      justify-content: space-between;
  height: 65px;
}

.solutionLicensed {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;

  color: #424242;
}
.solutionTicked {
  width: 15.5px;
  height: 13px;
  margin-left: 10.5px;
}

.solutionWatchVedio {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;

  /* or 162% */
  cursor: pointer;
  /* button red */
  color: #eb4734;
}
.modal-dialog {
  max-width: 900px !important;
  margin: 1.75rem auto;
}
.modal-backdrop {
  text-align: end !important;
}

.closeButton {
  position: absolute;
  margin: 18px;
  cursor: pointer;
  z-index: 1000;
  right: 0;
}

.video-height {
  height: 100% !important;
  margin-bottom: -9px !important;
}
@media only screen and (min-width: 1440px) {
  .modal-dialog {
    max-width: 80% !important;
    margin: 1.75rem auto;
  }
  /* .multiSelectContainer {
    max-width: 100% !important;
  } */
}

.directIntegrationCopyButton {
  min-width: 72px;
  float: right;
  color: #fff !important;
  text-transform: capitalize !important;
  outline: none !important;
}

.copy-msg-direct {
  /* display: inline-block; */
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  font-weight: 100 !important;
  color: #797979;
  padding: 10px;
  box-sizing: border-box;
  max-width: 350px;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 25%);
  position: absolute;
  z-index: 36px;
  margin-left: 23px;
  margin-top: -30px;
  right: 129px;
  background-color: white;
}
[data-reach-menu-item][data-selected] {
  background: #eb4734;
}

.export-btn {
  background: transparent;
  outline: none;
}
[aria-label='Download'] {
  color: #eb4734 !important;
}
[data-reach-menu-button]:hover {
  text-decoration: none;
  outline: none;
  border: 1px solid transparent !important;
}

[data-reach-menu-item][data-selected] {
  background: #eb4734;
}

.export-btn {
  background: transparent;
  outline: none;
}
[aria-label='Download'] {
  color: #eb4734 !important;
}
[data-reach-menu-button]:hover {
  text-decoration: none;
  outline: none;
  border: 1px solid transparent !important;
}

[data-reach-menu-item][data-selected] {
  background: #eb4734;
}

.export-btn {
  background: transparent;
  outline: none;
}
[aria-label='Download'] {
  color: #eb4734 !important;
}
[data-reach-menu-button]:hover {
  text-decoration: none;
  outline: none;
  border: 1px solid transparent !important;
}

.view-download-button {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
}

.view-download-meta {
  width: 25px;
  cursor: pointer;
}

.display-none-view {
  visibility: hidden;
}

.solution-type-home {
  height: 22px;
  background: #ebc259;
  width: 112px;
  margin-bottom: 7px;
}
.dataset-type-home {
  height: 22px;
  background: #c7d6e3;
  width: 76px;
  margin-bottom: 7px;
}
.solution-type {
  position: absolute;
  top: 9px;
  right: 0;
  height: 22px;
  background: #ebc259;
  width: 112px;
}
.dataset-type {
  position: absolute;
  top: 9px;
  right: 0;
  height: 22px;
  background: #c7d6e3;
  width: 76px;
}

.solution-type-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  text-align: center;
  -ms-flex-pack: center;
      justify-content: center;
  color: #424242;
}
@media screen and (min-width: 370px) and (max-width: 499px) {
  .delivery-type-main {
    padding-left: 10px !important;
    -ms-flex-direction: column !important;
        flex-direction: column !important;
  }
}
@media screen and (min-width: 500px) and (max-width: 770px) {
  .delivery-type-main {
    padding-left: 40px !important;
  }
}
@media screen and (min-width: 771px) and (max-width: 1023px) {
  .delivery-type-main {
    padding-left: 30px !important;
  }
}
.delivery-type-main {
  display: -ms-flexbox;
  display: flex;
  grid-gap: 4px;
  gap: 4px;
  padding-left: 95px;
}

.bulk {
  width: 50px;
  height: 21px;
  background: #477a99;
}
.cloud {
  width: 65px;
  height: 21px;
  background: #e16d26;
}
.api {
  width: 41px;
  height: 21px;
  background: #8a649e;
}
.solutionDetail-delivery {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  text-align: center;
  -ms-flex-pack: center;
      justify-content: center;
  color: #ffffff;
}

.checkbox-main {
  display: -ms-flexbox;
  display: flex;
  grid-gap: 30px;
  gap: 30px;
  padding-left: 25px;
  margin-top: 20px;
}

.checkbox-sc {
  width: 23px;
  height: 23px;
  left: 1014px;
  top: 254px;
  margin-top: 7px;
  margin-right: 0 !important;
  background: #ffffff;
  border: 1px solid #8d8d8d;
  accent-color: rgb(235, 71, 52);
  cursor: pointer;
}

.checkbox-label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  /* or 144% */
  color: #000000;
  cursor: pointer;
}

.checkbox-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
}

.wrap-check {
  display: -webkit-inline-box;
}

.propertyNav {
  width: 140px;
  height: 21px;
  background: #a00c00;
}

/* Password change */

.input_labels {
  color: #000000;
  font-size: 15px;
  font-weight: 500px;
}

.success_case {
  position: relative;
  /* padding-left: 20px; */
  text-align: start;
  font-size: 12px;
  color: green;
  margin: 0px !important;
}

.error_case {
  position: relative;
  /* padding-left: 20px; */
  text-align: start;
  font-size: 12px;
  color: red;
  margin: 0px;
}

.success_case::before {
  position: relative;
  content: '✔';
  margin-right: 12px;
}

.error_case::before {
  position: relative;
  margin-right: 8px;
  top: 2px;
  content: url(/assets/images/warning_icon.png);
}

.success-msg {
  color: #000000;
}

.pass-container {
  margin-top: 5px !important;
  margin-bottom: 10px !important;
}

.passwordChange-dialogueBox {
  margin: 0 auto;
}
/* End of Password change */
/* Error Component CSS Starts here */

.err-main-container {
  padding-top: 50px;
  padding-left: 50px;
}

.err-main-heading {
  font-weight: 700;
  font-size: 24px;
  font-family: 'Open Sans';
  color: black;
  line-height: 42px;
}

.err-sub-heading {
  margin: 10% 0% 10% 0% !important;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Open Sans';
  color: black;
  line-height: 28px;
}

/* End of Error Component */

.DeleteConfirmButton_Dialog__1fK1F {
  width: 500px;
  border-radius: 2px;
  box-shadow: var(--box-shadow-2);
}

.DeleteConfirmButton_dialogContent__W3Sh_ {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  font-size: 1.5rem;
  margin-bottom: 16px;
}

.DeleteConfirmButton_buttonWrapper__DqUmO {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
}

.DeleteConfirmButton_buttons__gi7ni {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.DeleteConfirmButton_buttons__gi7ni {
  width: 200px;
  border-color: transparent;
  background-color: #ff0000;
  color: white;
  font-size: 14px;
  font-family: Arial;
}

.DeleteConfirmButton_buttons__gi7ni:hover {
  background-color: #cf0707 !important;
  color: white !important;
  font-size: 14px;
  font-family: Arial;
}

.Drawer_Dialog__3ZE53 {
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: absolute;
  box-shadow: var(--box-shadow-2);
}

.Drawer_titleWrapper__jSTRe {
  box-sizing: border-box;
  height: 44px;
  font-size: 1.3rem;
  border-bottom: var(--border);
  display: -ms-flexbox;
  display: flex;
  padding: 5px 16px;
  line-height: 1.5;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.03);
}

.Drawer_dialogBody__1EzlJ {
  height: calc(100vh - 44px);
  /* 
   TODO move this padding into a separate component for dialog body, 
   used as needed for each specific drawer 
   Another component can be introduced for an "Actions" component that sits outside the body, 
   with body being scrollable and grows
  */
  padding: 16px;
  overflow-y: auto;
}

.ListItem_ListItem__DkhCD {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
  min-height: 48px;
  border-bottom: var(--border);
}

/* Using techniques from https://www.filamentgroup.com/lab/select-css.html */
.Select_select__2-QYP {
  display: inline-block;
  font-family: sans-serif;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='black'><polygon points='0,0 100,0 50,50'/></svg>"),
    linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 10px top 14px, 0 0;
  background-size: 0.65em auto, 100%;
  font-variant: tabular-nums;
  font-feature-settings: 'tnum', 'tnum', "tnum";
  position: relative;
  height: 32px;
  padding: 4px 30px 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

/* from https://www.filamentgroup.com/lab/select-css.html */
.Select_select__2-QYP::-ms-expand {
  display: none;
}

/* Support for rtl text, explicit support for Arabic and Hebrew */
*[dir='rtl'] .Select_select__2-QYP,
:root:lang(ar) .Select_select__2-QYP,
:root:lang(iw) .Select_select__2-QYP {
  background-position: left 0.7em top 14px, 0 0;
  padding: 0.6em 0.8em 0.5em 1.4em;
}

/* Disabled styles */
.Select_select__2-QYP:disabled,
.Select_select__2-QYP[aria-disabled='true'] {
  color: graytext;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='black'><polygon points='0,0 100,0 50,50'/></svg>"),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
}
.Select_select__2-QYP:disabled:hover,
.Select_select__2-QYP[aria-disabled='true'] {
  border-color: #aaa;
}

/* Other SQLPad styles */
.Select_select__2-QYP:focus,
.Select_select__2-QYP:hover {
  border: 2px solid var(--primary-color);
  outline: 0;
  /* padding adjustment prevents added border from shifting text */
  padding: 3px 29px 3px 10px;
  background-position: right 9px top 13px, 0 0;
}

.Select_danger__1yZdE {
  border-color: var(--secondary-color);
  box-shadow: inset 0 1px 1px var(--secondary-color-30);
}

.Select_danger__1yZdE:focus {
  border: 2px solid var(--secondary-color);
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}

.Select_danger__1yZdE:hover {
  border-color: var(--secondary-color);
}

.Select_danger__1yZdE::-webkit-input-placeholder {
  color: var(--secondary-color);
}

.Select_danger__1yZdE:-ms-input-placeholder {
  color: var(--secondary-color);
}

.Select_danger__1yZdE::-ms-input-placeholder {
  color: var(--secondary-color);
}

.Select_danger__1yZdE::placeholder {
  color: var(--secondary-color);
}

/* Padding of 2 added to show link outline when focused */
.QueryList_ListItem__5gFWQ {
  margin-top: 2px;
  padding: 2px;
}

.QueryList_ListItem__5gFWQ:hover {
  background-color: #f4f4f4;
  transition: background-color 0.15s ease-in-out;
}

.QueryList_listItemActions__1Sovi {
  position: absolute;
  right: 8px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.QueryList_filterContainer__1_M9i {
  border-bottom: var(--border);
  background-color: rgba(0, 0, 0, 0.03);
  padding: 16px;
  /* 
    Negative margins in place to compensate for padding in Drawer 
    Should probably update Drawer implementation to have a subheader/actions section
  */
  margin: -16px -16px 16px -16px;
}

.QueryList_filterRow__1eHeh {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 8px;
}

.QueryList_queryLink__1IQgY {
  width: 100%;
  padding: 8px;
}

.QueryList_newWindowLink__3_rsr {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
}

.Tag_tagContainer__axqx_ {
  margin: 2px;
  padding: 3px 8px;
  display: inline-block;
  border-radius: 2px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -ms-flex-align: center;
      align-items: center;
  border: 1px solid rgb(218, 218, 218);
  background-color: rgb(243, 243, 243);
}

.Tag_tagCloseButton__B-L1R {
  cursor: pointer;
  line-height: 0.8;
  border: none;
  background-color: transparent;
  padding: 0px;
  font-size: 16px;
  margin-left: 6px;
}

.Tag_tagCloseButton__B-L1R:hover,
.Tag_tagCloseButton__B-L1R:focus {
  outline: 2px solid var(--primary-color);
}

.Tag_CloseIcon__QszSK {
  margin-top: 2px;
}

.QueryPreview_preview__29omD {
  position: fixed;
  left: 640px;
  top: 40px;
  right: 40px;
  bottom: 40px;
  background-color: white;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 16px;
  box-shadow: var(--box-shadow-1);
}

.QueryPreview_previewQueryName__2uino {
  font-size: 1.25rem;
}

.SchemaSidebar_schema__HZ9RS {
  cursor: default;
  font-size: 12px;
  line-height: 22px;
  font-family: Monaco, Menlo, 'Ubuntu Mono', Consolas, source-code-pro,
    monospace;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.SchemaSidebar_table__13TE5 {
  padding-left: 20px;
  cursor: default;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 22px;
  font-family: Monaco, Menlo, 'Ubuntu Mono', Consolas, source-code-pro,
    monospace;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.SchemaSidebar_schema__HZ9RS:hover,
.SchemaSidebar_table__13TE5:hover {
  background-color: #f4f4f4;
}

.SchemaSidebar_column__126R4 {
  padding-left: 54px;
  cursor: default;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 22px;
  font-family: Monaco, Menlo, 'Ubuntu Mono', Consolas, source-code-pro,
    monospace;
}

.SchemaSidebar_schemaSpinner__An3e8 {
  text-align: center;
  padding-top: 100px;
  width: 100%;
  height: 100%;
}

.QueryResultHeader_toolbar__2UhtN {
  height: 30px;
  border-bottom: 1px solid #ccc;
  background-color: #f4f4f4;
  /* line-height: 30px; */
  padding-left: 4px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.QueryResultHeader_toolbarItem__2Zlye {
  padding-right: 48px;
  padding-left: 4px;
}

.QueryResultHeader_iconLinkWrapper__38hqA {
  height: 30px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  line-height: 1;
}

.QueryResultHeader_iconLink__6Y_cL {
  margin-left: 16px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  line-height: 1;
}

.QueryResultHeader_exportButton__3l9Jt {
  position: absolute;
  right: 0;
  top: -3px;
}

.QueryResultHeader_informationIcon__AQhSc {
  width: 18px;
  position: relative;
  color: #ff3516;
  bottom: -3px;
}

.QueryResultHeader_tooltip__SubWx {
  white-space: pre-line;
  border: 0px;
}
.QueryResultHeader_tooltip__SubWx:hover {
  border: 0px;
  cursor: inherit;
  background-color: unset;
}

.FormExplain_formExplain__PR_U0 {
  clear: both;
  display: inline-block;
  min-height: 22px;
  margin-top: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  line-height: 1.5;
}

.TextArea_textarea__1bvIz {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  height: 10em;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.TextArea_textarea__1bvIz:focus {
  border: 2px solid var(--primary-color);
  /* padding adjustment prevents added border from shifting text */
  padding-left: 10px;
  padding-right: 10px;
  outline: 0;
}

.TextArea_textarea__1bvIz:hover {
  border: 2px solid var(--primary-color);
  /* padding adjustment prevents added border from shifting text */
  padding-left: 10px;
  padding-right: 10px;
}

.TextArea_textarea__1bvIz::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.45);
}

.TextArea_textarea__1bvIz:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.45);
}

.TextArea_textarea__1bvIz::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.45);
}

.TextArea_textarea__1bvIz::placeholder {
  color: rgba(0, 0, 0, 0.45);
}

.TextArea_danger__3V0tn {
  border-color: var(--secondary-color);
  box-shadow: inset 0 1px 1px var(--secondary-color-30);
}

.TextArea_danger__3V0tn:focus {
  border: 2px solid var(--secondary-color);
  outline: 0;
}

.TextArea_danger__3V0tn:hover {
  border-color: var(--secondary-color);
}

.TextArea_danger__3V0tn::-webkit-input-placeholder {
  color: var(--secondary-color);
}

.TextArea_danger__3V0tn:-ms-input-placeholder {
  color: var(--secondary-color);
}

.TextArea_danger__3V0tn::-ms-input-placeholder {
  color: var(--secondary-color);
}

.TextArea_danger__3V0tn::placeholder {
  color: var(--secondary-color);
}

@-webkit-keyframes ConnectionDropdown_attention__1hhgt {
  55% {
    box-shadow: 0 0 0px red;
  }
  70% {
    box-shadow: 0 0 9px red;
  }
  85% {
    box-shadow: 0 0 0px red;
  }
}

@keyframes ConnectionDropdown_attention__1hhgt {
  55% {
    box-shadow: 0 0 0px red;
  }
  70% {
    box-shadow: 0 0 9px red;
  }
  85% {
    box-shadow: 0 0 0px red;
  }
}

.ConnectionDropdown_attention__1hhgt {
  -webkit-animation-name: ConnectionDropdown_attention__1hhgt;
          animation-name: ConnectionDropdown_attention__1hhgt;
  -webkit-animation-duration: 6s;
          animation-duration: 6s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.Modal_Dialog__3In5b {
  box-shadow: var(--box-shadow-2);
  padding: 0;
}

.Modal_titleWrapper__dT84L {
  border-bottom: var(--border);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  box-sizing: border-box;
  -ms-flex: 0 0 45px;
      flex: 0 0 45px;
  font-size: 1.3rem;
  padding: 8px 16px;
  line-height: 1.5;
  -ms-flex-align: center;
      align-items: center;
  background-color: rgba(0, 0, 0, 0.03);
}

.Modal_dialogBody__M4L7o {
  padding: 16px;
}

.Logo_logo__1vvHH {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  margin-left: 30px;
  margin-top: -6px;
  margin-bottom: -6px;
  margin-right: 50px;
}
.Logo_logo__1vvHH img {  
  width: 195px;
}
/* .logo img {
  height: 100px;
} */

.report-style-class {
  height: 100vh;
  padding: 0px;
  margin: 0 auto;
  width: 100%;
  background-color: #e9e9e9;
}

body {
  font-family: 'Open Sans';
  margin: 0;
}

.header {
  background: #3476ae 0 0 no-repeat padding-box;
  border: 1px solid #707070;
  height: 55px;
  left: 0;
  top: 0;
}

.displayMessage {
  color: #000000;
  font: normal 22px/27px Open Sans;
  letter-spacing: 0;
  margin-top: 1%;
  opacity: 1;
  text-align: center;
}

.hr {
  border: 1px solid #e0e0e0;
  opacity: 1;
}

.controls {
  margin-top: 1%;
  text-align: center;
}

.footer {
  background: #eef3f8 0 0 no-repeat padding-box;
  bottom: 0;
  height: 39px;
  opacity: 1;
  position: absolute;
  width: 100%;
}

.footer-text {
  font: Regular 16px/21px Segoe UI;
  height: 21px;
  letter-spacing: 0;
  margin-top: 9px;
  opacity: 1;
  position: relative;
  text-align: center;
  width: 100%;
}

.footer-text > a {
  color: #278ce2;
  font: Regular 16px/21px Segoe UI;
  letter-spacing: 0;
  text-decoration: underline;
}

.title {
  color: #ffffff;
  font: Bold 22px/27px Segoe UI;
  letter-spacing: 0;
  margin: 13px;
  margin-left: 36px;
  opacity: 1;
  text-align: left;
}

button {
  background: #337ab7;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  font-size: medium;
  height: 35px;
  margin-right: 15px;
  width: 150px;
}

button:onfocus {
  outline: none;
}

iframe {
  border: none;
}

.loadingMsg {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 100vh;
  font-size: 22px;
}
.run-btn {
  background-color: #eb4734;
  border-color: #eb4734;
  width: 70px;
  height: 35px;
  color: white;
}
.run-btn:hover {
  background-color: #dd3c30;
  border-color: #dd3c30;
  color: white;
}

.login-btn {
  width: 115px;
  height: 25px;
  background: #4e9caf;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  line-height: 25px;
  cursor: pointer;
}

/* Sign in */

body {
  color: #797979;
  background: #fff;
  font: 18px/1.6667 'Open Sans', 'Arial', 'Helvetica Neue', 'Helvetica',
    sans-serif;
  min-width: 320px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.masthead {
  height: 590.61px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.navbar.nav-bg-colour {
  background-color: #1d1d1d !important;
}

.navbar.nav-bg-colour .nav-link {
  line-height: 40px;
  height: 40px;
  padding: 0 16px 0 16px;
  width: auto;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  text-align: left;
  text-decoration: none;
  border: 0;
  border-radius: 0;
  font-family: inherit;
  font-size: 18px;
  font-weight: 700;
  outline: none;
}
.btn-try-elevate:hover {
  color: #ffffff;
}
.signIn-btn:hover {
  color: #ff2918;
}
.btn-try-elevate {
  width: 190px;
  height: 40px;
  border-radius: 0px;
  background: #ee4036;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  -ms-flex-align: center;
      align-items: center;
  text-align: center;
  color: #ffffff;
  margin-right: 47px;
}

.signIn-btn {
  height: 40px;
  width: 190px;
  border-radius: 0px;
  background: #ffffff;
  border: 1px solid #ee4036;
  box-sizing: border-box;
  border-radius: 0%;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  -ms-flex-align: center;
      align-items: center;
  text-align: center;
  color: #ee4036;
}

.content-bg {
  background-color: #1d1d1d;
}

.hover {
  cursor: pointer;
}

.password-link {
  color: #eb4734;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.password-link:hover {
  color: #eb4734;
}

.width-primary-interest {
  width: 70%;
  display: -ms-flexbox;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.primary-interest {
  outline: none;
  min-width: 50%;
  background-color: white;
  opacity: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  max-width: 80%;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 10px;
}

@media only screen and (min-width: 350px) and (max-width: 767px) {
  .width-primary-interest {
    width: 100% !important;
  }
  .primary-interest {
    min-width: 100%;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .width-primary-interest {
    width: 95% !important;
  }
}
@media only screen and (min-device-width: 1025px) and (max-device-width: 1260px) {
  .width-primary-interest {
    width: 80% !important;
  }
}
@media screen and (max-width: 992px) {
  .invite-team-row {
    grid-gap: 1px !important;
    gap: 1px !important;
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
}

.solution-row-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 28px;
  color: #424242;
  margin-bottom: 25px;
  margin-top: 13px;
}

.CloudObject_cloudDesc__2ZeaD p {
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 18px;
  line-height: 25px;
  color: #424242;
}
.CloudObject_cloudDesc__2ZeaD div {
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 18px;
  line-height: 25px;
  color: #424242;
}

.CloudObject_heading__cAc_b {
  font-weight: 700;
}

.CloudObject_mainContainer__CenWm {
  padding-top: 14px;
  padding-bottom: 15px;
}

.CloudObject_detailmodel__25boy {
  color: #eb4734;
  cursor: pointer;
  font-weight: bolder;
}
.CloudObject_list-row__2kusk {
  display: inline;
}

.CloudObjectModel_entiName__3akh- {
  width: 30%;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
}
.CloudObjectModel_entiDesc__lpWVU {
  width: 70%;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
}

.CloudObjectModel_DataValue__3oAyx {
  display: -ms-flexbox;
  display: flex;
  padding: 19px;
  border-bottom: 1px solid #d9d9d9;
  width: 90%;
  -ms-flex-pack: center;
      justify-content: center;
  grid-gap: 26px;
  gap: 26px;
}

.CloudObjectModel_DataValue__3oAyx:last-child {
  border-bottom: none;
}

.CloudObjectModel_closeimg__1_7Nw {
  float: right;
  margin-top: -2%;
  margin-right: -2%;
  cursor: pointer;
}

.CloudObjectModel_modalHeading__3zUj- {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #424242;
}

@media screen and (min-width: 320px) and (max-width: 1220px) {
  .CloudObjectModel_DataValue__3oAyx {
    -ms-flex-direction: column;
        flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
  }
  .CloudObjectModel_entiDesc__lpWVU,
  .CloudObjectModel_entiName__3akh- {
    width: 100%;
  }
  .CloudObjectModel_closeimg__1_7Nw {
    margin-top: -4%;
    margin-right: -4%;
  }
}
@media screen and (min-width: 2250px) and (max-width: 3900px) {
  .CloudObjectModel_closeimg__1_7Nw {
    padding: 18px 21px;
  }
}

